import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
function Dsubcategory() {
  const [coursedata, setCourseData] = useState([]);
  const [filterdata, setFilterData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://sdmipl.com/portal/api/internship/coursepost-view");
        setCourseData(response.data.records);
        console.log(response.data.records, 'headerfetching');
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const uniquedata = coursedata.filter(
      (item) =>
        item.category ===
      "UI/UX Course" || item.category === "3" || item.category === "design"
    );
    setFilterData(uniquedata);
  }, [coursedata]);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="subcateogory">
            <div>
              <Link to="/ourdesigncourses">
                <button className="Subbutton-main">
                  <b>UI/UX Designer</b>
                </button>
              </Link>
              <button className="clippath">clippath</button>
              {filterdata && filterdata.length > 0 ? (
                filterdata.map((course, index) => (
                  <span key={index}>
                    <Link to={`/uiuxCourse/${course.slug}`}>
                      <button className="subtn">{course.title}</button>
                    </Link>
                  </span>
                ))
              ) : (
                <span className="loader"></span>
              )}
              {/* <Link to="/blender">
                <button className="subtn">Blender</button>
              </Link>
              <Link to="/adobePhotoshop">
                <button className="subtn">Adobe Photoshop</button>
              </Link>
              <Link to="/swift">
                <button className="subtn">Swift</button>
              </Link>
              <Link to="/bootstrap">
                <button className="subtn">Bootstrap</button>
              </Link>
              <Link to="/tailwind">
                <button className="subtn">Tailwind</button>
              </Link>
              <Link to="/jquery">
                <button className="subtn">Jquery</button>
              </Link>
              <Link to="/material">
                <button className="subtn">Material Ui</button>
              </Link>
              <Link to="/chakra">
                <button className="subtn">Chakara Ui</button>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dsubcategory;
