import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import RichText from '../../Subpages/RichTexteditor';
import { Link } from 'react-router-dom';

const Subscription_plans = ({ handleUpdate, initialFormData }) => {
    const [formData, setFormData] = useState(() => {
        return initialFormData || {
            title: '',
            overview:'',
            description: '',
            duration:'',
            price:'',
            image: null
        };
    });

    const handleChange = (e, name) => {
        if (name === 'image') {
            const file = e.target.files[0];

            setFormData({
                ...formData,
                [name]: file || formData[name],
            });
        } else {
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("form", formData)
        try {
            const Formdatasend = new FormData();
            Formdatasend.append('title', formData.title);
            Formdatasend.append('overview', formData.overview);
            Formdatasend.append('internship_mode', formData.internship_mode);
            Formdatasend.append('description', formData.description);
            Formdatasend.append('price', formData.price);
            Formdatasend.append('duration', formData.duration);
            if (formData.image && typeof formData.image !== 'string') {
                Formdatasend.append('image', formData.image);
            }
           
            const res = await axios.post('https://sdmipl.com/portal/api/internship/subscription-create', Formdatasend);
            console.log('Plans', res);
            Swal.fire({
                icon: "success",
                title: "Successfully",
                text: ' Plan Add Successfully!! ',
            });
            handleUpdate();
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Try Again....",
                text: 'Server error, not responding',
            });
            console.log(error, 'something is wrong');
        }
    };
    return (
        <div className='container comapnay_form'>
            <div className='card card_design p-3 mt-3'>
                <div className="col-lg-12">
                    <nav aria-label=" breadcrumb ">
                        <ol className="breadcrumb  p-3">
                            <li className="breadcrumb-item "><Link to="/general-setting" className='brlink '>Home</Link></li>
                            <li className="breadcrumb-item" aria-current="page">Add Subscription Plan</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='upbtn'>
                <button onClick={handleUpdate}>Back</button>
            </div>
            <div className="row mt-2">
                <div className="col-lg-12 mt-5">
                    <div className='registerWrapper'>
                        <div className="twm-tabs-style-2">
                            <form className="row align-items-center" onSubmit={handleSubmit} encType='multipart/form-data'>
                                <div className='category-container'>
                                    <h4>Add Subscription Plan</h4>
                                    <button type="submit" className="site-button" >
                                        Add Subscription Plan
                                    </button>
                                </div>
                          
                                <div className='col-lg-12'>
                                    <div className='imgaddmain'>
                                        <div  className='pic'>
                                            {formData.image ? (
                                                <img id="picsub" src={formData.image instanceof File ? URL.createObjectURL(formData.image) : formData.image} alt="Selected" />
                                            ) : (
                                                <input type="file" accept="image/*" name='image' className='form-control' onChange={(e) => handleChange(e, 'image')} />
                                            )}
                                            <label htmlFor="plan_image" className="form-label"> Plans Image</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <label htmlFor="plan_title" className="form-label"> Plans Title</label>
                                    <input
                                        type='text'
                                        name="title"
                                        placeholder={"Plan_title "}
                                        onChange={handleChange}
                                        className='form-control'
                                    />
                                </div>

                                <div className="col-lg-6 ">
                                    <div className="form-group">
                                        <label className='form-label' htmlFor='internship_mode'> Internship Mode</label>
                                        <select
                                            name="internship_mode"
                                            className="form-control form-select"
                                            onChange={handleChange}
                                        >
                                            <option value=''>Select Internship Mode</option>
                                            <option>
                                                Online-Mode
                                            </option>
                                            <option >
                                                Offline-Mode
                                            </option>
                                            <option>
                                                Hybird
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="form-group">
                                        <label className='form-label' htmlFor='duration'> Duration</label>
                                        <select
                                            name="duration"
                                            className="form-control form-select"
                                            onChange={handleChange}
                                        >
                                            <option value=''>Select Duration</option>
                                            <option>
                                                3 Month
                                            </option>
                                            <option >
                                                6 Month
                                            </option>
                                            <option >
                                                1 Year
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="price" className="form-label">Course Price</label>
                                    <input
                                        type='text'
                                        name="price"
                                        placeholder={"Course Price"}
                                        onChange={handleChange}
                                        className='form-control'
                                    />
                                </div>

                                <div className="col-lg-12">
                                    <label htmlFor="overview" className="form-label"> Overview </label>
                                    <RichText
                                        name="overview"
                                        placeholder={"Overview"}
                                        onChange={handleRichTextChange}
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="description" className="form-label"> Description </label>
                                    <RichText
                                        name="description"
                                        placeholder={"Description of Plans"}
                                        onChange={handleRichTextChange}
                                        className='form-control'
                                        rows={4}
                                    />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Subscription_plans;
