import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineCategory } from "react-icons/md";
import { FaBook } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";
import { IoTimerSharp } from "react-icons/io5";
import { MdAccessTime } from "react-icons/md";
import { FiActivity } from "react-icons/fi";
import html from "../../images/html.jpg";
import axios from 'axios';

function Selectedcourse() {
  const userId = localStorage.getItem("id");
  const [formData, setFormData] = useState({});
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchLoggedInUserEmail = () => {
      const loggedInUserEmail = localStorage.getItem("userEmail");
      setFormData(prevState => ({ ...prevState, email: loggedInUserEmail }));
    };
    fetchLoggedInUserEmail();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://sdmipl.com/portal/api/internship/registration-single-record/${userId}`);
        console.log(response, 'gyegewg')
        setData(response.data.records);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [userId]);
  return (
    <>
      <div className="container">
        <div className='card card_design p-3 mt-3'>
          <div className="col-lg-12">
            <nav aria-label=" breadcrumb ">
              <ol className="breadcrumb  p-3">
                <li className="breadcrumb-item "><Link to="/dashboard" className='brlink'>Home</Link></li>
                <li className="breadcrumb-item" aria-current="page">Profile</li>
              </ol>
            </nav>
          </div>
        </div>
        <h3 className="heading">Course Details</h3>
        <div className="main-body">
          <div className="row">
            <div class="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    {/* <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" class="rounded-circle" width="150" /> */}
                    <img className="puser py-2" src={html} alt='' />
                    <div className="mt-3">
                      <p className="text-secondary mb-1">Hypertext Markup Language(HTML)</p>
                      <p className="text-muted font-size-sm">Frontend Development</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-4">
                      <h6 className="mb-0"> <MdOutlineCategory className="mx-1" />Course Catergory :</h6>
                    </div>
                    <div className="col-sm-8 text-secondary">
                    Frontend  Development
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-4">
                      <h6 className="mb-0"><FiActivity className="mx-1" /> Course Curriculam :</h6>
                    </div>
                    <div className="col-sm-8 text-secondary">
                    {"Html"} {"CSS"}{" Bootstrap"}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-4">
                      <h6 className="mb-0"><IoIosLink className="mx-1" /> Seassion Links :</h6>
                    </div>
                    <div className="col-sm-8 text-secondary">
                    www.sdmipl.com
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-4">
                      <h6 className="mb-0"><IoTimerSharp className="mx-1" /> Seassion Start :</h6>
                    </div>
                    <div className="col-sm-8 text-secondary">
                    01/05/2024
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-4">
                      <h6 className="mb-0"><MdAccessTime className="mx-1" />Seassion End:</h6>
                    </div>
                    <div className="col-sm-8 text-secondary">
                    31/05/2024
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Selectedcourse;