import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { LinearProgress } from '@mui/material'
import HomeUpdate from './HomeUpdate';
import { Link } from 'react-router-dom';
import { FaPen } from 'react-icons/fa';

const Homeseo = () => {
    const [formOpen, setFormOpen] = useState(false);
    const [Loading, setLoading] = useState(true);
    const [siteconfig, setSiteconfig] = useState('');
    const handleUpdate = () => {
        setFormOpen(!formOpen);
    };
    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await axios.get('http://127.0.0.1:8000/api/get-homepage/')

                setSiteconfig(response.data)
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        }
        fetch()
    }, [formOpen])
    if (Loading) {
        <div className='container'>
            <div className='row p-5'>
                <LinearProgress />
            </div>
        </div>
    }
    if (!siteconfig) {
        return (
          <div className='container'>
            <div className='row p-5'>
              <div className='col-12 p-1'>
                <LinearProgress />
              </div>
            </div>
          </div>
        );
      }
    return (

        <section className='upshowing'>
            <div className='container'>
                <div className="row">
                    <div className="col-lg-12" id='nav'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb p-3">
                                <li className="breadcrumb-item "><Link to="/admin-dashboard" title="Home page" className='text-decoration-none '>Admin -  dashboard</Link></li>
                                <li className="breadcrumb-item" aria-current="page">home-page</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {formOpen ? (

                    <HomeUpdate handleUpdate={handleUpdate} />

                ) :
                    <div className=''>
                        <div className='row align-items-center '>

                            <div className='col-2 upbtn d-flex justify-content-end'>
                                <button onClick={handleUpdate} className=''> <FaPen className='py-0.5' /> Edit</button>
                            </div>
                        </div>

                        <div className='row '>
                            <div className='col-lg-12'>
                                <div className='card card_design p-3 mt-3'>
                                    <h3>Home content:</h3>

                                    <div className='row'>
                                        <div className='first'>
                                            <div className='main_head'>
                                                <h5>{siteconfig.title}</h5>
                                            </div>
                                            <div className='content'>
                                                <h5>Description</h5>
                                                <p dangerouslySetInnerHTML={{ __html: siteconfig.description }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='card card_design p-3 mt-3'>
                                    <h3>Seo Parameters</h3>
                                    <div className='row'>
                                        <div className='first col-md-5'>
                                            <h4>Seo-Title</h4>
                                            <h4>Seo-Keywords</h4>
                                            <h4>Seo Description</h4>
                                            <h4>Seo Canonical-links</h4>
                                        </div>
                                        <div className='second col-md-7'>
                                            <p>{siteconfig.seo_title}</p>
                                            <p>{siteconfig.seo_keywords}</p>
                                            <p>{siteconfig.seo_description}</p>
                                            <p>{siteconfig.canonical_links}</p>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                }
            </div>
        </section>
    )
}

export default Homeseo;