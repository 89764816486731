import React, { useState } from 'react';
import { Link, } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { IoIosMail } from "react-icons/io";
import { FaEye, FaRegEyeSlash } from 'react-icons/fa';
function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    user_role: "",
  });
  const [viewPassword, setViewPassword] = useState(false);
console.log(formData,"login")
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const passwordViewHandler = () => {
    setViewPassword(!viewPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post('https://sdmipl.com/portal/api/login-view', formData);
      const accessToken = res.data.access_token        ;

      console.log("response-login",res)
      console.warn("accestoken",accessToken);
      setToken(accessToken);
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('id', res.data.id);
      const role = res.data.user_role;

      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: 'success',
        text: formData.email,
        title: 'Login successfully !',
      });
      // try {
      //   if (role === 'admin') {
      //     localStorage.setItem('user_role', role);
      //     navigate("/admin-dashboard");
      //   } else {

      //     localStorage.setItem('user_role', role);
      //     navigate("/");
      //   }
      // } catch {
      //   console.log("User not Found");
      // }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Try Again....',
        text:error.response.data.comment ||'Please enter a valid email & password',
        // text: 'Please enter a valid email & password',
      });
      console.error('Please enter a valid email & password:', error);
    }

  };

  return (
    <>
    <div className='wave-main'>
        <svg xmlns="http://www.w3.org/2000/svg" className='animationwave' viewBox="0 0 1440 320" >
          <path fill="#0099ff" fill-opacity="1" d="M0,256L24,250.7C48,245,96,235,144,213.3C192,192,240,160,288,133.3C336,107,384,85,432,112C480,139,528,213,576,234.7C624,256,672,224,720,192C768,160,816,128,864,138.7C912,149,960,203,1008,213.3C1056,224,1104,192,1152,170.7C1200,149,1248,139,1296,138.7C1344,139,1392,149,1416,154.7L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path> </svg>
        <div className="wave-display">
          <ol className="breadcrumb">
            <li className="breadcrumb-item "><Link to="/" className='brlink'><b>Home</b></Link></li>
            <li className="breadcrumb-item" aria-current="page"><Link to="#"><b>Login</b></Link></li>
          </ol>
        </div>
      </div>
      {/* Login section start */}
      <section className="container wavi loginsection section section-lg section-header position-relative min-vh-100 flex-column d-flex justify-content-center">
      <h3 className='text-center'>
            <strong>Login Now</strong>
          </h3>
        <div id="loginform">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7 col-lg-6">
                <div className="hero-content-left text-white font-weight-bold">
                  <h1 className="text-start fw-bolder px-3">Welcome Back!</h1>
                  <p className="lead px-3">
                    "Fellow code adventurer! Embrace the challenges, conquer the bugs, and let the programming journey continue. Happy coding!"
                  </p>
                </div>
              </div>
              <div className="loginform col-md-5 col-lg-5 m-3">
                <div className="card login-signup-card shadow-lg mb-0">
                  <div className="card-body">
                    <div className="mb-5">
                      <h3>Login</h3>
                      <p className="text-muted">
                        Sign in to your account to continue.
                      </p>
                    </div>
                    {/* Login form */}
                    <form className="login-signup-form">
                      <div className="form-group">
                        <label className="rgform">Email Address</label>
                        <div className="input-group input-group-merge">
                          <div className="input-icon">
                            <IoIosMail />
                          </div>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="email@gmail.com"
                            name="email"
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      {/* Password */}
                      <div className="form-group">
                        <div className="row">
                          <div className="col">
                            <label className="rgform">Password</label>
                          </div>
                        </div>
                        <div className="input-group input-group-merge">
                          <div className="input-icon">
                            <p className='password-eye' onClick={passwordViewHandler}> {viewPassword ? <FaEye /> : <FaRegEyeSlash />} </p>
                          </div>
                          <input
                            name='password'
                            type={viewPassword ? 'text' : 'password'}
                            placeholder="Enter your password"
                            className="form-control"
                            onChange={handleChange}
                          />

                        </div>
                      </div>
                      {/* Submit */}
                      <button
                        type="submit"
                        className="signbtn"
                        onClick={handleSubmit}
                      >
                        Sign in
                      </button>
                    </form>
                  </div>
                  <div className="card-footer bg-soft text-center border-top px-md-5">
                    <small>Not registered? </small>
                    <Link to="/registration" className="small formlink">
                      Create Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </section>
      {/* Login section end */}
    </>
  );
}

export default Login;
