import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import RichText from '../Subpages/RichTexteditor';
import { LinearProgress } from '@mui/material';
import { FaBackward } from 'react-icons/fa';

const Privacypolicyupdate = ({handleUpdate}) => {
    const [Loding , setLoding] = useState(true);

    const [formData, setFormData] = useState({
        title: '',
        policy: "",
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
    });
    // const navigate = useNavigate();
    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await axios.get('http://127.0.0.1:8000/api/get-privacy-policy')
                setFormData(response.data)
                setLoding(false)
            } catch (error) {
                console.log(error)
            }
        }
        fetch()
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handelsubmit = async (e) => {
        e.preventDefault();
        try {
           
            const response = await axios.post('http://127.0.0.1:8000/api/update-privacy-policy/', formData);
            console.log("res", response)
            if (response.status === 200) {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Privacy-policy has been saved",
                    showConfirmButton: false,
                    timer: 1500
                  });
                handleUpdate()
            } else {
                Swal.fire({
                    icon: "error",
                    title: "There was an error in updating the data. Please try again.",
                });
                handleUpdate()
            }
        } catch (error) {
            console.log("axios", error)
        }
    }
    if (Loding) {
        <div className='container'>
            <div className='row p-5'>
                <LinearProgress />
            </div>
        </div>
    }
    return (
        <div className="container ">
            
            <div className='upbtn'>

                <button onClick={handleUpdate}> <FaBackward /> back </button>
            </div>
            <form className='shadow p-2 ' onSubmit={handelsubmit} encType='multipart/form-data'>

                <div className="row m-3">

                    <div className="col-lg-12">
                        <label htmlFor="title" className="form-label">Title</label>
                        <input
                            type="text"
                            name='title'
                            onChange={handleInputChange}
                            value={formData.title}
                            className="form-control"
                            placeholder="Enter title"
                        />
                    </div>

                    <div className="col-lg-12">
                        <label htmlFor="policy" className="form-label">Privacy-Policy</label>
                        <RichText
                            name="policy"
                            placeholder={"privacy-policy"}
                            value={formData.policy}
                            onChange={handleRichTextChange}
                        />

                    </div>

                   <div className='row mt-3 '>
                    <h6 className='py-2'>Seo-Parameters</h6>
                    <div className="col-lg-6">
                        <label htmlFor="seo_title" className="form-label">Seo-Title</label>
                        <input
                            type="text"
                            name='seo_title'
                            onChange={handleInputChange}
                            value={formData.seo_title}
                            className="form-control"
                            placeholder="Enter Seo link"
                        />
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="seo_keywords" className="form-label">Seo-Keywords</label>
                        <input
                            type="text"
                            name='seo_keywords'
                            onChange={handleInputChange}
                            value={formData.seo_keywords}
                            className="form-control"
                            placeholder="Enter Seo keywords"
                        />
                    </div>


                    <div className="col-lg-12">
                        <label htmlFor="seo_description" className="form-label">Seo-Description</label>
                        <textarea
                            type="text"
                            rows={3}
                            name='seo_description'
                            onChange={handleInputChange}
                            value={formData.seo_description}
                            className="form-control"
                            placeholder="Enter Seo Description"
                        />
                    </div>
                    </div>
                </div>
                <div className='d-flex float-right'><button type='submit' className="btn btn-primary">Submit</button></div>
            </form >
        </div >

    );
}
export default Privacypolicyupdate