import { NavLink, Outlet,useNavigate } from "react-router-dom";
import { FaBars, FaHome,} from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { AiFillHeart, AiTwotoneFileExclamation } from "react-icons/ai";
import { IoBook } from "react-icons/io5";
import { BiSolidBookAdd } from "react-icons/bi";
import { SiAmazondocumentdb } from "react-icons/si";
import { FaBook } from "react-icons/fa6";
import { BsPersonLinesFill } from "react-icons/bs";
import { PiStudentDuotone } from "react-icons/pi";
import { useEffect, useState } from "react";
import { MdSubscriptions ,MdOutlineSubscriptions} from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import Topbar from "../topbar/Topbar";
import logo from '../../images/sionlogo.webp'

const routes = [
  {
    path: "/admin-dashboard",
    name: "Dashboard",
    icon: <FaHome />,
  },
   {
    path: "/admin-dashboard/general-settings",
    name: "Courses-Settings",
    icon: <BiSolidBookAdd />,
    subRoutes: [
      {
        path: "/admin-dashboard/general-settings/CourseCategory",
        name : "Course-Category",
        icon: <IoBook />,
      },
     
      {
        path: "/admin-dashboard/general-settings/ViewCoursePost",
        name : "Course-Post",
        icon: <FaBook />,
      },
    ]
  },
  {
    path:"/admin-dashboard/general-settings/Records",
    name:"Records",
    icon: <SiAmazondocumentdb />,
    subRoutes:[
        {
          path:"/admin-dashboard/general-settings/Records/interns_record",
          name:"Interns",
          icon: <PiStudentDuotone />,
        },
        {
          path:"/admin-dashboard/general-settings/Records/registered_interns",
          name:"Registerd_Interns",
          icon: <PiStudentDuotone />,
        },
        {
          path:"/admin-dashboard/general-settings/Records/staff_record",
          name:"Staff",
          icon: <BsPersonLinesFill />,
        }
    ]
  },
  {  
    path:"/admin-dashboard/general-settings/Subscription/subscription_plans",
    name:"Subscription Plans",
    icon:<MdSubscriptions />,
    subRoutes:[
      // {
      //   path:"/admin-dashboard/general-settings/Subscription/subscription_plans",
      //   name:"Add Subscription",
      //   icon:<BiSolidMessageAdd />
        
      // },
      {
          path:"/admin-dashboard/general-settings/viewsubscription",
          name:"View Subscription",
          icon:<MdOutlineSubscriptions />
      }
    ]
  },
  {
    path: "/admin-dashboard/contact",
    name: "Contact-Us",
    icon: <AiTwotoneFileExclamation />,
  },

  {
    path: "/admin-dashboard",
    name: "Saved",
    icon: <AiFillHeart />,
  },
];

const SideBar = ({ children }) => {
  const [search, setSearch] = useState('');
  console.log("searchdata",search);
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
//   const role = localStorage.getItem("user_role")
//    useEffect(()=>{
//     if(role !=="admin"){
//       navigate('/');
//  }
//    } , [role])
  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "110px",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "230px" : "45px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.img
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                  src={logo}
                  alt="New Project"

                />
              )}
            </AnimatePresence>

            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>
          <div className="search">
            <div className="search_icon">
              <BiSearch />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  value={search}
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
            </AnimatePresence>
          </div>

          <section className="routes">
            {routes
              .filter((route) =>
                search.toLowerCase() === '' ? true : route.name.toLowerCase().includes(search.toLowerCase())
              )
              .map((route, index) => {
                if (route.subRoutes) {
                  route.subRoutes.filter((route) =>
                    search.toLowerCase() === '' ? true : route.name.toLowerCase().includes(search.toLowerCase())
                  )
                  return (
                    <SidebarMenu
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                      width={100}
                    />
                  );
                }
                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                    activeClassName="active"
                  >
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
          </section>
        </motion.div>

        <main className="centerbar">
          <Topbar />

          {children}
          <Outlet />

        </main>
      </div >
    </>
  );
};

export default SideBar; 
