import React, { useEffect, useState } from "react";
import { MdBackHand } from 'react-icons/md';
import { Breadcrumb } from 'react-bootstrap';
import { IoMdPerson } from "react-icons/io";
import { TfiEmail } from "react-icons/tfi";
import { LuPhoneCall } from "react-icons/lu";
import { FaBook } from "react-icons/fa";
import { IoInvertMode } from "react-icons/io5";
import { MdCurrencyRupee } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { MdOutlineCategory } from "react-icons/md";
import axios from "axios";
import html from "../../images/html.jpg";
const Dashboardintern = () => {
  const [formData, setFormData] = useState({});
  const [records, setRecords] = useState({});
  const userId = localStorage.getItem("id");

  useEffect(() => {
    const fetchLoggedInUserEmail = () => {
      const loggedInUserEmail = localStorage.getItem("userEmail");
      setFormData(prevState => ({ ...prevState, email: loggedInUserEmail }));
    };
    fetchLoggedInUserEmail();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://sdmipl.com/portal/api/login-single_record/${userId}`);
        console.log(response.data.records, 'login')
        setFormData(response.data.records);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [userId]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://sdmipl.com/portal/api/internship/registration-single-record/${userId}`);
        setRecords(response.data.records);
        console.log(response.data.records, 'records')
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [userId]);
  console.log(userId, 'userId')
  return (
    <div className="container-fluid common_dash">
      <div className="row align-items-center top mt-3">
        <div className="col-lg-6">

          <h3>Welcome User <MdBackHand className="text-warning anim" /> !</h3>

        </div>
        <div className="col-lg-6">
          <Breadcrumb className="bread">
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {/* <div className="row card ">
          <div className="col-lg-12 col-md-12 col">
            <div className="row align-items-center py-3 justify-content-around">
              <h3 className="heading">Course Details</h3>
              <div className="profildiv card col-md-6">
                <img className="puser" src={html} alt='' />
                <div>
                  <span className="cntent fw-bold"><IoMdPerson className="mx-1" />
                    Name : </span><span className="cntent fw-bold">{records.name}</span>
                </div>
              </div>
              <div className="card col-md-6 col">
                <div className="mb-2">
                  <div className="mb-2">
                    <span className="fw-bold"><MdOutlineCategory className="mx-1"  />
                      Selected Category: </span> <span className="cntent">{records.intrestedcourse} </span>
                  </div>
                  <span className="fw-bold"><FaBook className="mx-1" />
                    Course :</span> <span className="cntent">{records.selected_course} </span>
                </div>
                <div className="mb-2">
                  <span className="fw-bold"><TfiEmail className="mx-1" /> Email : </span>
                  <span className="cntent">{records.email}</span>
                </div>
                <div className="mb-2">
                  <span className="fw-bold"><LuPhoneCall className="mx-1" />Mobile : </span> <span className="cntent">{records.mobile || records.number}</span>
                </div>
                <div className="mb-2">
                  <span className="fw-bold"><IoInvertMode className="mx-1"  />
                    Mode : </span> <span className="cntent">{records.mode}</span>
                </div>
                <div className="mb-2">
                  <span className="fw-bold"><MdCurrencyRupee className="mx-1" />
                    Price : </span> <span className="cntent">{records.price}</span>
                </div>
                <div className="mb-2">
                  <span className="fw-bold"><FaRegCalendarAlt className="mx-1"  />
                    Duration : </span> <span className="cntent">{records.duration}</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <h3 className="heading">User Profile</h3>
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" className=" rounded-circle" width="150" />
                    <div className="mt-3">
                      <h4>{formData.name}</h4>
                      <p className="text-secondary mb-1">{records.intrestedcourse}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0"><FaBook className="mx-1" /> Course :</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {records.selected_course}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0"><IoInvertMode className="mx-1" />Mode :</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {records.mode}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0"><MdCurrencyRupee className="mx-1" />Price :</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {records.price}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0"><FaRegCalendarAlt className="mx-1"  />Duration :</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {records.duration}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0"><TfiEmail className="mx-1" />Email :</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {records.email}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0"><LuPhoneCall className="mx-1" />Mobile :</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                    {records.mobile || records.number}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Dashboardintern;
