import React, { useEffect, useState } from 'react'
import { FaHandPointRight } from "react-icons/fa";
import { PiArrowFatLinesRightDuotone } from "react-icons/pi";
import code from '../../src/images/code.jpg'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
function Pricing() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://sdmipl.com/portal/api/internship/subscription-view')
        const pricing = response.data.records;
        if (response.status === 200) {
          setData(pricing);
        } else {
          console.log("Api not responding");
        }
      }
      catch (err) {
        console.log('Something fetching Error', err);
      }
    }

    fetchData();
  }, []);
  console.log('pricing plan', data);
  return (
    <>
      <div className='wave-main'>
        <svg xmlns="http://www.w3.org/2000/svg" className='animationwave' viewBox="0 0 1440 320" >
          <path fill="#0099ff" fill-opacity="1" d="M0,256L24,250.7C48,245,96,235,144,213.3C192,192,240,160,288,133.3C336,107,384,85,432,112C480,139,528,213,576,234.7C624,256,672,224,720,192C768,160,816,128,864,138.7C912,149,960,203,1008,213.3C1056,224,1104,192,1152,170.7C1200,149,1248,139,1296,138.7C1344,139,1392,149,1416,154.7L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path></svg>
        <div className="wave-display">
          <ol className="breadcrumb">
            <li className="breadcrumb-item "><Link to="/" className='brlink'><b>Home</b></Link></li>
            <li className="breadcrumb-item" aria-current="page"><Link to='#'><b>Pricing</b></Link></li>
          </ol>
        </div>
      </div>
      <div className='pricinghead mb-3'>
        <strong>Our Pricing  For Plans</strong>
      </div>

      <div className='container wavi main_price  '>
        {
          data.slice(0,3).map((item) => (
            <>
              <div key={item.id}>
                <div className="card-basic">
                  <div className="card-header header-basic">
                    <h1 title={item.title}>{item.title.slice(0,15)}
                    </h1>
                  </div>
                  <div className="card-body">
                    <div className="card-element-hidden-basic px-2">
                      <div className="pricingimgcrds">
                        <img src={code} />
                      </div>
                      <div className='crdprice'>
                        <p className='crprs'>
                          <span>Rs.{item.price}</span> /<sub>{item.duration}</sub></p>
                      </div>
                      <p className='text-start pitoverview' title={item.overview}> {item.overview}.</p>
                      <p className='pricecrdtxt' title={item.description}><span className='me-2'><PiArrowFatLinesRightDuotone /> 
                      </span> {item.description.slice(0,item.description.length)}</p>

                      <p className='pricecrdtxt'><span className='me-2'><PiArrowFatLinesRightDuotone />
                      </span> Online & Offline Classes </p>
                      <Link to={`/billing/${item.id}`} title={item.title} > <button className="btn btn-basic pbtn" id="vbtn">Buy now</button></Link>
                    </div>
                  </div>
                </div>

                {/* <div className="card-standard">
                    <div className="card-header header-standard">
                      <h1>Backend</h1>
                    </div>
                    <div className="card-body">
                      <div className="card-element-hidden-basic px-2">
                        <div className="pricingimgcrds">
                          <img src={code} />
                        </div>
                        <div className='crdprice'>
                          <p className='crprs'> <span>{item.price}</span> /<sub>6 Months</sub></p>
                        </div>
                        <p className='text-start'>Unlock lifetime access to our extensive backend development course, meticulously crafted to empower you with the expertise required to thrive in web development.
                        </p>
                        <p className='pricecrdtxt'><span className='me-2'><PiArrowFatLinesRightDuotone />
                        </span>Comprehensive Notes</p>

                        <p className='pricecrdtxt'><span className='me-2'><PiArrowFatLinesRightDuotone />
                        </span>Online and Offline Classes</p>
                        <Link to="/" className="btn btn-basic pbtn" id="vbtn">Buy now</Link>
                      </div>
                    </div>
                  </div> */}
                {/* <div className="card-premium">
                    <div className="card-header header-premium">
                      <h1>Full Stack</h1>
                    </div>
                    <div className="card-element-hidden-basic px-2">
                      <div className="pricingimgcrds">
                        <img src={code} />
                      </div>
                      <div className='crdprice'>
                        <p className='crprs'>
                          <span>15999</span> / <sub>1 Year</sub></p>
                      </div>
                      <p className='text-start'>Embark on a transformative journey with our full-stack web development course, offering lifetime access to a comprehensive curriculum designed to equip you with the skills needed to excel in both frontend and backend development.</p>
                      <p className='pricecrdtxt'><span className='me-2'><PiArrowFatLinesRightDuotone />
                      </span>Comprehensive Notes</p>

                      <p className='pricecrdtxt'><span className='me-2'><PiArrowFatLinesRightDuotone />
                      </span>Online and Offline Classes</p>
                      <Link to="/" className="btn btn-basic pbtn" id="vbtn">Buy now</Link>
                    </div>
                  </div> */}
              </div>
            </>
          ))
        }
      </div>
    </>
  )
}

export default Pricing;