import React from 'react'
import featureswcu from '../images/featureswc-bg.png';
import pricingwcs from '../images/pricing_wcs.png';
import supportwcs from '../images/support_wcs.png';
import delivery_wcs from '../images/deliver_wcs.png';
import product_wcs from '../images/product_wcs.png';
import appoach_wcs from '../images/approach_wcs.png';
import experience_wcs from '../images/experience_wcs.png';
import 'animate.css';

function Whychooseus() {
    return (
        <>
        <section className='whychooseus'>
            <div className='Container'>
                <div className='row'>
                    <p className='fw-bold fs-3 text-center text-info'>Why Choose Us</p>
                    <p className='text-center py-3 '>
                        <strong>Sion Datamatics India Pvt. Ltd .</strong>
                    </p>
                </div>
                <div className='row'>
                    <div className='col-lg-4 leftwcs '>
                        <div className='content d-flex py-3 animate__animated animate__fadeInLeftBig'>
                            <div className='descriptionwcs'>
                                <h4 className='text-end'>Experience</h4>
                                <p className="text-end">Ronsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div className='imageswcu'>
                            <img className='img-fluid' src={experience_wcs} />
                            </div>
                        </div>
                        <div className='content d-flex py-3 animate__animated animate__fadeInLeftBig'>
                            <div className='descriptionwcs'>
                                <h4 className='text-end'>Products</h4>
                                <p className="text-end">Ronsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div className='imageswcu'>
                            <img className='img-fluid' src={product_wcs} />
                            </div>
                        </div>
                        <div className='content d-flex py-3 animate__animated animate__fadeInLeftBig'>
                            <div className='descriptionwcs'>
                                <h4 className='text-end'>Approach</h4>
                                <p className="text-end">Ronsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div className='imageswcu'>
                                <img className='img-fluid' src={appoach_wcs} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 wcscenter d-flex align-items-center'>
                        <img className='img-fluid' src={featureswcu} alt='why Choose us' />
                    </div>
                    <div className='col-lg-4 rightwcs'>
                        <div className='content d-flex py-3 animate__animated animate__fadeInRightBig'>
                            <div className='imageswcu'>
                                <img className='img-fluid' src={pricingwcs} />
                            </div>
                            <div className='descriptionwcs'>
                                <h4 className='text-start'>Pricing</h4>
                                <p className='text-start'>Ronsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                            </div>
                        </div>
                        <div className='content d-flex py-3 animate__animated animate__fadeInRightBig'>
                            <div className='imageswcu'>
                                <img className='img-fluid' src={delivery_wcs} />
                            </div>
                            <div className='descriptionwcs'>
                                <h4 className='text-start'>Delivery
                                </h4>
                                <p className='text-start'>Ronsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                            </div>
                        </div>
                        <div className='content d-flex py-3 animate__animated animate__fadeInRightBig'>
                            <div className='imageswcu'>
                                <img className='img-fluid' src={supportwcs} />
                            </div>
                            <div className='descriptionwcs'>
                                <h4 className='text-start'>Support</h4>
                                <p className='text-start'>Ronsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </>
    )
}

export default Whychooseus;
