import React from 'react'
import Bsubcategory from '../category/bsubcategory'
import { Outlet } from 'react-router-dom'
function layout3() {
  return (
    <>
       <Bsubcategory /> 
       <Outlet />
    </>
  )
}

export default layout3