import React from "react";
import sionlogo from "../images/main logo.webp";
import { MdDoubleArrow } from "react-icons/md";

import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="footer__btm ">
          <div className="footer__menu  pt-5">
            <div className="row px-5 justify-content-center">
              <div className="col-lg-6 col-md-3 col-sm-12">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={sionlogo} id="logoh" alt="Sion_logo" className="img-fluid" title="sion logo" />
                </div>
                <h4 className="fhead">
                  BEST IT COMPANY IN INDORE
                  <span className="ms-2"> MADHYA PRADESH INDIA</span>
                </h4>

                <div className="footer-icon py-4 d-flex justify-content-center ">
                  <div className="d-flex justify-content-center"><h3 className="fmh fs-5 py-1">Follow Us :</h3></div>
                  <div className="d-flex justify-content-center">
                    <i className="fa-brands fa-facebook" title="facebook"></i>
                    <i className="fa-brands fa-instagram" title="instagram"></i>
                    <i className="fa-brands fa-square-twitter" title="twitter"></i>
                    <i className="fa-brands fa-youtube" title="youtube"></i>
                  </div>

                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12">
                <h3 className="fmh">HomePages</h3>
                <ul className="list-unstyled">
                  <li> <Link to="/"><span className="fs-5"><MdDoubleArrow /></span>  Home  </Link></li>
                  <li> <Link to="/"><span className="fs-5"><MdDoubleArrow /></span> Explore Courses</Link></li>
                  <li> <Link to="/features"><span className="fs-5"><MdDoubleArrow /></span> Features</Link></li>
                  <li> <Link to="/pricing"><span className="fs-5"><MdDoubleArrow /></span> Pricing</Link></li>
                </ul>

              </div>
              <div className="col-lg-2 col-md-3 col-sm-12 ">
                <h3 className="fmh">Our Courses</h3>
                <ul className="list-unstyled">
                  <li><Link to="/OurFrontendCourses"> <span className="fs-5"><MdDoubleArrow />
                  </span>FrontEnd </Link></li>
                  <li><Link to="/OurBackendCourses"><span className="fs-5"><MdDoubleArrow /></span> BackEnd</Link></li>
                  <li><Link to="/OurDesignCourses"><span className="fs-5"><MdDoubleArrow /></span>  Ui&Ux Design </Link></li>
                  <li><Link to="/"><span className="fs-5"><MdDoubleArrow /></span>  Learn More</Link> </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12">
                <h3 className="fmh">Our Services</h3>
                <ul className="list-unstyled">
                  <li><Link><span className="fs-5"><MdDoubleArrow /></span> About Us</Link></li>
                  <li><Link to="/contact"><span className="fs-5"><MdDoubleArrow /></span> Contact us</Link></li>
                  <li><Link to="/PrivacyPolicy"><span className="fs-5"><MdDoubleArrow /></span> Privacy Policy</Link></li>
                  <li><Link to="/terms&condition"><span className="fs-5"><MdDoubleArrow /></span> Terms & Conditions</Link></li>
                </ul>
              </div>
            </div>
            <div className="row px-3 bg-dark">
              <div className="col-lg-12 d-flex justify-content-center align-items-center">
                <p className="fw-bold">
                  Copyright © {new Date().getFullYear()}  Sion DataMatics India Pvt. Ltd.
                </p>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default Footer;
