import React from 'react'
import SideBar from '../Sidebar/Sidebar'
import '../../assets/css/admin.css';
function LayoutDashboard() {
  return (
   <>
   
   <SideBar />  
    {/* admin Dashboard */}

   </>
  )
}

export default LayoutDashboard;