import React from 'react'
import Header from '../header';
import { Outlet } from 'react-router-dom';
import Home from '../home';
import Footer from '../footer';

function Layout() {
  return (
     <>
     <Header />
     <Home />
     <Outlet />
     <Footer />
     </>
  )
}

export default Layout;