import React, { useState, useEffect, useRef } from "react";
import logo from "../images/sionlogo.webp";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BsPersonCircle } from "react-icons/bs";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { FaUser } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';
import axios from "axios";
import Searchbox from "./searchbox";

function Header() {
  const [searchTitle, setSearchtitle] = useState('');
  const [isExplorecourseOpen, setIsExplorecourseOpen] = useState(false);
  const exploreRef = useRef(null);
  const [coursedata, setCourseData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [visible, setIshide] = useState(true);
  const navbarCollapseRef = useRef(null);
  const navigate = useNavigate();
  const [toggel, setToggel] = useState(false);

  const hideCard = () => {
    setIshide(false);
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (exploreRef.current && !exploreRef.current.contains(event.target)) {
        setIsExplorecourseOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleExplorecourse = () => {
    setIsExplorecourseOpen(prevState => !prevState);
    setIshide(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://sdmipl.com/portal/api/internship/coursepost-view");
        setCourseData(response.data.records);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCourseClick = (courseId, category) => {
    switch (category) {
      case "Frontend Course":
      case "Frontend":
      case "1":
        navigate(`/frontendCourse/${courseId}`);
        break;
      case "Backend Course":
      case "Backend":
      case "2":
        navigate(`/backendcourse/${courseId}`);
        break;
      case "UI/UX Course":
      case "3":
      case "design":
        navigate(`/uiuxCourse/${courseId}`);
        break;
      default:
        break;
    }
    setIsExplorecourseOpen(false);
  };

  const handleInputChange = (e) => {
    setSearchtitle(e.target.value);
  };

  useEffect(() => {
    const searchcourse = async () => {
      try {
        const response = await axios.get(`https://sdmipl.com/portal/api/internship/coursepost-view/?search=${searchTitle}`);
        setSearchtitle(response.data);
      } catch (error) {
        if (error.response && error.response.status === 429) {
          await new Promise(resolve => setTimeout(resolve, 1000));
          await searchcourse();
        } else {
          console.error('Error fetching user data:', error);
        }
      }
    };

    searchcourse();
  }, []);

  const handellogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user_role');
    setIsLoggedIn(false);
    navigate("/login")
  }

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);
  const handleNavLinkClick = ()=>{
    // alert("dfgsgsd")
    // if (navbarCollapseRef.current){
      // navbarCollapseRef.current.classList.remove('show')
      setToggel("false")
    // }
  }
  return (
    <>
      <nav className="main-header navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link className="navbar-brand mx-4" to="/">
            <img src={logo} alt="logo" style={{ width: "90px" }} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={toggel?"true":"false"}
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2">
              <li className="nav-item">
                <NavLink className="nav-link menubar" to="/" activeClassName="active" >
                  <b>Home</b>
                </NavLink>
              </li>
              <li>
                <button
                  className="explore button"
                  // activeClassName="active"
                  onMouseEnter={() => setIsExplorecourseOpen(true)}
                  onMouseLeave={() => setIsExplorecourseOpen(true)}
                  onClick={toggleExplorecourse}>
                  <b id="drhlink">ExploreCourses</b>
                </button>
              </li>
              <li className="nav-item nav-text">
                <NavLink className="nav-link menubar" activeClassName="active" to="/features" onClick={handleNavLinkClick}>
                  <b>Features</b>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link menubar" activeClassName="active" to="/pricing">
                  <b>Pricing</b>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link menubar" activeClassName="active" to="/Contact">
                  <b>Contact us</b>
                </NavLink>
              </li>
            </ul>
            {/* searchbox */}
            <Searchbox />
            {isLoggedIn ? (
              <div className="d-flex justify-content-end">
                <Dropdown>
                  <Dropdown.Toggle variant="light" id="user-dropdown">
                    <FaUser />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item><Link className='brlink' id="drhlink" to={'/dashboard'}>Profile</Link></Dropdown.Item>
                    <Dropdown.Item onClick={handellogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <div className=" loginbtn d-flex justify-content-end mr-5">
                <Dropdown>
                  <Dropdown.Toggle variant="light" id="user-dropdown">
                    <BsPersonCircle />
                    &nbsp; Login
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item ><Link className='brlink' id="drhlink" to={'/login'}>Login</Link></Dropdown.Item>
                    <Dropdown.Item ><Link className='brlink' id="drhlink" to={'/registration'}>Registration</Link></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

          </div>
        </div>
      </nav>
      {visible &&
        <div ref={exploreRef} className="explore">
          {isExplorecourseOpen && (
            <div className="explorecourse  shadow-sm mx-auto row">
              <div className="d-flex justify-content-between">
                <b className="d-flex justify-content-center " id="drhlink"> Popular Certificate Courses</b>
                <div className="crs">
                  <ImCross className="crossIcon d-flex " onClick={hideCard} />
                </div>
              </div>
              <div className="exp col-lg-6 mt-3">
                {coursedata && coursedata.map((course, index) => (
                  <div className="expdrp" key={index} onClick={() => handleCourseClick(course.slug, course.category)}>
                    <Link className="dropdown-item" to="#">
                      <span><MdOutlineDoubleArrow /></span>
                      {course.title}
                      <hr />
                    </Link>
                  </div>
                ))}
                {!coursedata && (
                  <div className="expdrp" >
                    <Link className="dropdown-item" to="#">
                      <p>loading</p>
                      <div id="exp" className="loader"></div>
                      <hr />
                    </Link>
                  </div>

                )}
              </div>
              <div className=" secand_side col-lg-6 ">
                <Link to='/ourfrontendcourses' ><div className="cards"><b className="expbtnhead">Frontend Courses</b></div></Link>
                <Link to='/ourbackendcourses'><div className="cards"><b className="expbtnhead">Backend Courses</b></div></Link>
                <Link to='/ourdesigncourses'><div className="cards"><b className="expbtnhead">UI/UX Designer</b></div></Link>
                <div className="CExpertbtn">
                  <p>Confused about which Course to select?</p>
                  <Link to='/contact'><button className="expertbtn">Talk to Career Expert</button></Link>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </>
  );
}

export default Header;
