import React from 'react';
import { MdBackHand } from 'react-icons/md';
import { Breadcrumb } from 'react-bootstrap';

const Dashboard = () => {

  return (
    <div className="container-fluid common_dash">
      <div className="row align-items-center top mt-3">
        <div className="col-lg-6">

          <h3>Welcome Admin <MdBackHand className="text-warning anim" /> !</h3>

        </div>
        <div className="col-lg-6">
          <Breadcrumb className="bread">
            <Breadcrumb.Item href="/counsellor-dashboard">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
