import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import registrationform from "../images/registrationform.jpg";
import { IoIosMail } from "react-icons/io";
import { BsPersonCircle } from "react-icons/bs";
import { ImMobile } from "react-icons/im";
import { FaEye, FaRegEyeSlash } from 'react-icons/fa';

function Registration() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    number: '',
    user_role: 'user',
  });
  
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [viewPassword, setViewPassword] = useState(false);

  const passwordViewHandler = () => {
    setViewPassword(!viewPassword);
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    let errorMessage = '';

    if (name === 'name') {
      errorMessage = value.trim() === '' ? 'Name is required' : '';
    }
    else if (name === 'email') {
      errorMessage = !/\S+@\S+\.\S+/.test(value) ? 'Invalid Email Format' : '';
    }
    else if (name === 'mobile') {
      errorMessage = (value.length !== 10 || !/^\d+$/.test(value)) ? 'Invalid Mobile No.' : '';
    } else if (name === 'password') {
      errorMessage = value.length < 8 ? 'Password must be at least 8 characters long' : '';
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(errors).some(error => error !== '')) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: 'Please fix the errors before submitting the form',
      });
      return;
    }

    try {
      await axios.post("https://sdmipl.com/portal/api/register", formData);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: "Registration successful",
      });
      navigate("/login");
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Try Again....",
        text: 'Something Wrong',
      });
      console.error('Error submitting form:', err);
    }
  };

  return (
    <>
     <div className='wave-main'>
        <svg xmlns="http://www.w3.org/2000/svg" className='animationwave' viewBox="0 0 1440 320" >
          <path fill="#0099ff" fill-opacity="1" d="M0,256L24,250.7C48,245,96,235,144,213.3C192,192,240,160,288,133.3C336,107,384,85,432,112C480,139,528,213,576,234.7C624,256,672,224,720,192C768,160,816,128,864,138.7C912,149,960,203,1008,213.3C1056,224,1104,192,1152,170.7C1200,149,1248,139,1296,138.7C1344,139,1392,149,1416,154.7L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path> </svg>
        <div className="wave-display">
          <ol className="breadcrumb">
            <li className="breadcrumb-item "><Link to="/" className='brlink'><b>Home</b></Link></li>
            <li className="breadcrumb-item" aria-current="page"><Link to="#"><b>Registration</b></Link></li>
          </ol>
        </div>
      </div>
      {/* Registration section start */}
      <section>
        <div className="container wavi">
          <div className="row align-items-center">
          <h3 className='text-center'>
            <strong >Register Now</strong>
          </h3>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img
                src={registrationform}
                alt="registration"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="card login-signup-card mb-0 w-100">
                <div className="card-body">
                  <div className="mb-4">
                    <h3>Registration</h3>
                    <p className="text-muted">Back a Live Class, For Free!</p>
                  </div>
                  {/* login form */}
                  <form className="login-signup-form" onSubmit={handleSubmit}>
                    {/* Name */}
                    <div className="form-group">
                      <label className="rgform"> Name</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <BsPersonCircle />
                        </div>
                        <input
                          type="text"
                          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                          placeholder="Enter Your Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                      </div>
                    </div>
                    {/* Email */}
                    <div className="form-group">
                      <label className="rgform">Email</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <IoIosMail />
                        </div>
                        <input
                          type="email"
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          placeholder="Enter Email id"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                      </div>
                    </div>
                    {/* Mobile */}
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label className="rgform">Mobile No.</label>
                        </div>
                      </div>
                    </div>
                    <div className="input-group input-group-merge">
                      <div className="input-icon">
                        <ImMobile />
                      </div>
                      <input
                        type="text"
                        name="number"
                        className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                        placeholder="Enter Mobile No."
                        maxLength="10"
                        value={formData.mobile}
                        onChange={handleChange}
                        required
                      />
                      {errors.mobile && <div className="invalid-feedback">{errors.mobile}</div>}
                    </div>
                    {/* Password */}
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label className="rgform">Password</label>
                        </div>
                      </div>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <p className='password-eye' onClick={passwordViewHandler}>
                            {viewPassword ? <FaEye /> : <FaRegEyeSlash />}
                          </p>
                        </div>
                        <input
                          type={viewPassword ? 'text' : 'password'}
                          name="password"
                          className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                          placeholder="Enter Password"
                          value={formData.password}
                          onChange={handleChange}
                          required
                        />
                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                      </div>
                    </div>
                    {/* Submit */}
                    <button type="submit"className="registerbtn mt-2">Register</button>
                  </form> 
                </div>
                <div className="card-footer bg-soft text-center">
                  <small>Already have an account ? </small>
                  <Link to="/login" className="small">Login here.</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Registration section end */}
    </>
  );
}

export default Registration;
