import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { FaSearch } from "react-icons/fa";
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Link } from 'react-router-dom';
import AddCoursepost from '../Courses/AddCoursepost';
import MUIDataTable from 'mui-datatables';

const Staff_record = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addForm, setAddForm] = useState(false);
    const [input, setInput] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [fileInput, setFileInput] = useState(null);
    const userPerPage = 10;
    const columns = [
        {
            label: "S.No",
            name: '#',
        },
        {
            name: "Name",
            label: "Name",
        },
        {
            name: "Email",
            label: "Email",
        },
        {
            name: "Position",
            label: "Position",
        },
        {
            name: "Course",
            label: "Course",
        },
        {
            name: "Mode",
            label: "Mode",
        },
        {
            name: "Action",
            label: "Action",
        },
    ];
    const options = {
        filterType: 'checkbox',
        selectableRows: false,
        rowsPerPageOptions: [5, 10, 20, 30, 50]
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://sdmipl.com/portal/api/internship/coursepost-view");
                setData(response.data.records);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [addForm]);

    const handleUpdate = (id) => {
        setAddForm(!addForm);
    };

    const handleDelete = async (id) => {
        try {
            await axios.post(`https://sdmipl.com/portal/api/internship/coursepost-delete/${id}`);
            const newData = data.filter(item => item.id !== id);
            setData(newData);

            Swal.fire({
                icon: 'success',
                title: "Successfully Deleted",
                showConfirmButton: false,
                timer: 2000
            });

        } catch (error) {
            console.error('There was a problem with your delete operation:', error);
        }
    };

    const filteredData = data.filter(item =>
        item.title.toLowerCase().includes(input.toLowerCase())
    );

    const pageCount = Math.ceil(filteredData.length / userPerPage);
    const offset = pageNumber * userPerPage;

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
    };

    const handleFileChange = (event) => {
        setFileInput(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        try {
            const formData = new FormData();
            formData.append('file', fileInput);
            // Make a POST request to upload the file
            const response = await axios.post("https://sdmipl.com/portal/api/upload-file", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // Handle the response
            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    if (loading) {
        return (
            <div className='container'>
                <div className='row p-3 py-10'>
                    <LinearProgress />
                </div>
            </div>
        );
    }
    return (
        <div className="container upshowing">
            <div className="row">
                {addForm ? (
                    <AddCoursepost handleUpdate={handleUpdate} handleFileUpload={handleFileUpload} />
                ) :
                    <div className="row slider_page">

                        <div className='card card_design p-3 mt-3'>
                            <div className="col-lg-12">
                                <nav aria-label=" breadcrumb ">
                                    <ol className="breadcrumb  p-3">
                                        <li className="breadcrumb-item "><Link to="/general-setting" className='brlink '>Home</Link></li>
                                        <li className="breadcrumb-item" aria-current="page">Staff Records</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-lg-12 d-flex justify-content-end">
                            <div className='row align-items-center '>
                                <div className=' upbtn d-flex justify-content-end'>
                                    <button onClick={handleUpdate}>Add</button>
                                </div>
                            </div>
                            <div className="row g-3 p-3">
                                <div className=" col-lg-10 p-3 m-auto">
                                    <label htmlFor="search" className="visually-hidden">search</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="search" placeholder="Search here" onChange={(e) => setInput(e.target.value)} />
                                        <FaSearch id='srh-icon' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='tbl'>
                            <MUIDataTable
                                title={"Staff List"}
                                data={filteredData.slice(offset, offset + userPerPage).map((item, index) => [
                                    index + 1, item.title , item.category, item.price, item.price, item.internship_mode,
                                    <div className='actbtn d-flex'>
                                        <button className='btn btn-primary me-2' onClick={() => handleUpdate(item.id)}>Edit</button>
                                        <button className='btn btn-danger me-2' onClick={() => handleDelete(item.id)}>Delete</button>
                                    </div>
                                ])}
                                columns={columns}
                                options={options}
                            />
                        </div>
                        {/* <div className="shadow-sm p-2 bg-light  table-responsive">
                            <Table className="table table-striped table-hover">
                                <Thead>
                                    <Tr>
                                        <Th scope="col">#</Th>
                                        <Th scope="col">Name</Th>
                                        <Th scope="col">Email</Th>
                                        <Th scope="col">Course</Th>
                                        <Th scope="col">Price</Th>
                                        <Th scope="col">Mode</Th>
                                        <Th scope="col">Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {filteredData.length > 0 ? (
                                        filteredData.slice(offset, offset + userPerPage).map((item, index) => (
                                            <Tr key={index}>
                                                <Td >{index + 1}</Td>
                                                <Td>{item.title}</Td>
                                                <Td>{item.category}</Td>
                                                <Td>{item.price}</Td>
                                                <Td>{item.price}</Td>
                                                <Td>{item.internship_mode}</Td>
                                                <Td className='d-flex'>
                                                    <button className='btn btn-primary me-2' onClick={() => handleUpdate(item.id)} >Edit</button>
                                                    <button className='btn btn-danger me-2' onClick={() => handleDelete(item.id)}>Delete</button>
                                                </Td>
                                            </Tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <Td colSpan="4">No data Found...</Td>
                                        </tr>
                                    )}
                                </Tbody>
                            </Table>
                        </div>
                        <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                            <ReactPaginate
                                nextLabel={<>Next </>}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                disabledClassName={'pagination__link--disabled'}
                                previousLabel="Previous"
                                containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                                breakClassName='page-item'
                                breakLinkClassName='page-link'
                                previousClassName='page-item'
                                previousLinkClassName='page-link'
                                nextClassName='page-item'
                                nextLinkClassName='page-link'
                                activeClassName='active  pb-3'
                                pageClassName='page-item ml-1 mr-1'
                                pageLinkClassName='page-link'
                            />
                        </div> */}

                    </div>
                }
            </div>
        </div>
    )
}

export default Staff_record;
