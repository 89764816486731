import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import RichText from '../../Subpages/RichTexteditor';
import { FaBackward } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const CoursecategoryUpdated = ({ handleUpdate, formData }) => {
    const [formState, setFormState] = useState({
        title: '',
        description: '',
        image: null,
    });

    useEffect(() => {
        setFormState(formData || {
            title: '',
            description: '',
            image: null,
        });
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleRichTextChange = (name, value) => {
        setFormState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setFormState(prevState => ({
            ...prevState,
            image: imageFile,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formDataToSend = new FormData();
            Object.entries(formState).forEach(([key, value]) => {
                if (value !== null) {
                    formDataToSend.append(key, value);
                }
            });
            const res = await axios.post(`https://sdmipl.com/portal/api/internship/category-update/${formState.id}`, formDataToSend);
            console.log(res, 'category update');

            Swal.fire({
                icon: 'success',
                title: 'Successfully',
                text: 'Update Course category',
            });

            handleUpdate();

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Server error, please try again.',
            });
            console.error('Error updating course category:', error);
        }
    };

    return (
        <div className="container comapnay_form">
            <div className="card card_design p-3 mt-3">
                <div className="col-lg-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb  p-3">
                            <li className="breadcrumb-item">
                                <Link to="/general-setting" className="brlink">
                                    Home
                                </Link>
                            </li>
                            <li className="breadcrumb-item" aria-current="page">
                                Update Course Category
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="upbtn">
                <button onClick={handleUpdate}>
                    {' '}
                    <FaBackward /> back{' '}
                </button>
            </div>
            <div className="row mt-2">
                <div className="col-lg-12 col-md-6 col-sm-12 mt-5">
                    <div className="registerWrapper">
                        <div className="twm-tabs-style-2">
                            <div className="row">
                                <div className="category-container">
                                    <h4>Update Category</h4>
                                    <button type="button" name="submit" className="site-button" onClick={handleSubmit}>
                                        Update Category
                                    </button>
                                </div>
                              
                                <div className="col-lg-12">
                                    <div className="form-group my-3">
                                        <label className="frmlabel" htmlFor="title">
                                            Title
                                        </label>
                                        <input
                                            name="title"
                                            type="text"
                                            className="form-control"
                                            placeholder="Course Category title"
                                            value={formState.title}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <label className="frmlabel" htmlFor="logo">
                                            Logo
                                        </label>
                                        <input
                                            name="image"
                                            type="file"
                                            className="form-control file-control"
                                            onChange={handleImageChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="terms" className="form-label">
                                        Description
                                    </label>
                                    <RichText
                                        name="description"
                                        placeholder="Description"
                                        value={formState.description}
                                        onChange={handleRichTextChange}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoursecategoryUpdated;
