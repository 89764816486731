import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { FaBars,  FaInfoCircle } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { AiFillHeart } from "react-icons/ai";
import { IoBook } from "react-icons/io5";
import { SiAmazondocumentdb } from "react-icons/si";
import { FaBook } from "react-icons/fa6";
import { FaHome} from "react-icons/fa";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import Topbar from "../topbar/Topbar";
import logo from '../../images/sionlogo.webp';

const routes = [
    {
        path: "/dashboard/dashboardi",
        name: "Dashboard",
        icon: <FaHome />,
      },
    {
        path: '/dashboard/selectedcourse',
        name: 'Course Details',
        icon: <FaBook />,
    },
    {
        path: '/dashboard/upcominglecture',
        name: 'Upcoming Lecture',
        icon: <SiAmazondocumentdb />,
    },
    {
        path: '/dashboard/exercise',
        name: 'Exercise',
        icon: <IoBook />,
    },
    {
        path: "/dashboard/profile",
        name: "Profile ",
        icon: <FaInfoCircle />,
    },

    {
        path: "/dashboard/dashboardi",
        name: "Saved",
        icon: <AiFillHeart />,
    },
];

const Usersidebar = ({ children }) => {
    const [search, setSearch] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const navigate = useNavigate();
    const role = localStorage.getItem("user_role")
 
     useEffect(()=>{
      if(role !=="user"){
        navigate('/');
   }
     } , [role])
    const toggle = () => setIsOpen(!isOpen);
    const inputAnimation = {
        hidden: {
            width: 0,
            padding: 0,
            transition: {
                duration: 0.2,
            },
        },
        show: {
            width: "140px",
            padding: "5px 15px",
            transition: {
                duration: 0.2,
            },
        },
    };

    const showAnimation = {
        hidden: {
            width: 0,
            opacity: 0,
            transition: {
                duration: 0.5,
            },
        },
        show: {
            opacity: 1,
            width: "110px",
            transition: {
                duration: 0.5,
            },
        },
    };

    return (
        <>
            <div className="main-container">
                <motion.div
                    animate={{
                        width: isOpen ? "230px" : "45px",

                        transition: {
                            duration: 0.5,
                            type: "spring",
                            damping: 10,
                        },
                    }}
                    className={`sidebar `}
                >
                    <div className="top_section">
                        <AnimatePresence>
                            {isOpen && (
                                <motion.img
                                    variants={showAnimation}
                                    initial="hidden"
                                    animate="show"
                                    exit="hidden"
                                    className="logo"
                                    src={logo}
                                    alt="New Project"

                                />
                            )}
                        </AnimatePresence>

                        <div className="bars">
                            <FaBars onClick={toggle} />
                        </div>
                    </div>
                    <div className="search">
                        <div className="search_icon">
                            <BiSearch />
                        </div>
                        <AnimatePresence>
                            {isOpen && (
                                <motion.input
                                    initial="hidden"
                                    animate="show"
                                    exit="hidden"
                                    variants={inputAnimation}
                                    type="text"
                                    value={search}
                                    placeholder="Search"
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            )}
                        </AnimatePresence>
                    </div>

                    <section className="routes">
                        {routes
                            .filter((route) =>
                                search.toLowerCase() === '' ? true : route.name.toLowerCase().includes(search.toLowerCase())
                            )
                            .map((route, index) => {
                                if (route.subRoutes) {
                                    route.subRoutes.filter((route) =>
                                        search.toLowerCase() === '' ? true : route.name.toLowerCase().includes(search.toLowerCase())
                                    )
                                    return (
                                        <SidebarMenu
                                            setIsOpen={setIsOpen}
                                            route={route}
                                            showAnimation={showAnimation}
                                            isOpen={isOpen}
                                            width={100}
                                        />
                                    );
                                }
                                return (
                                    <NavLink
                                        to={route.path}
                                        key={index}
                                        className="link"
                                        activeClassName="active"
                                    >
                                        <div className="icon">{route.icon}</div>
                                        <AnimatePresence>
                                            {isOpen && (
                                                <motion.div
                                                    variants={showAnimation}
                                                    initial="hidden"
                                                    animate="show"
                                                    exit="hidden"
                                                    className="link_text"
                                                >
                                                    {route.name}
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </NavLink>
                                );
                            })}
                    </section>
                </motion.div>

                <main className="centerbar">
                    <Topbar />

                    {children}
                    <Outlet />

                </main>
            </div >
        </>
    );
};

export default Usersidebar; 
