import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { ImCross } from "react-icons/im";
const PriceCard = () => {
  const [visible , setIshide] = useState(true)
  const hideCard = () => {
    setIshide(false);
  };
  return (
    <>{ visible &&(

       <div className="sp-tabsform " style={{ width: "25%" }}>
        <div className="cross-btn">
          <ImCross className="crossIcon" onClick={hideCard} />
        </div>
        <Tabs defaultActiveKey="Personal" id="sp-tabsform">
          <Tab eventKey="Personal" title="Personal">
            <div className="sp-tabs-heading">
              <h3 className="tabs-heading">Subscribe to Sion's top Courses</h3>

              <p className="tabs-p">
                Get this course, plus <b>11,000+</b> of our top-rated courses,
                with Personal Plan
                <span id="tabs-lernmore"> Learn More</span>
              </p>
              <div style={{ textAlign: "center" }} className="pb-3">
                <button className="tabs-start my-2 p-1"> Start Now</button>
                <p>
                  Starting at <b>₹750</b> per month
                </p>
                <p>Cancel anytime</p>
                <div className="or">Or</div>
                <div className="tabsprice"> $7999 </div>
                <button className="buytabs my-2" but>
                  Buy This Course
                </button>
                <p>30-Day Money-Back Guarantee</p>
                <p> Full Lifetime Access</p>
              </div>
            </div>
          </Tab>
          <Tab eventKey="Team" title="Team" className="second-team">
            <div className="tab-back text-center">
              <div className="tabsprice"> $7999 </div>
              <button className="buytabs" but>
                Buy This Course
              </button>
              <h3 className="tabback">This course includes:</h3>
              <div className="tab">
                <span>7 hours on-demand video</span> <hr />
                <span>1 downloadable resource</span>
                <hr />
                <span> Access on mobile and TV</span>
                <hr />
                <span>Full lifetime access</span>
                <hr />
                <span> Certificate of completion</span>
                <hr />
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    )

    }
    </>
  );
};

export default PriceCard;
