import React, { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Topbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [Isdashboard, setIsDashboard] = useState(false);
    const handellogout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user_role');
        navigate("/login")
    };
    useEffect(() => {
        if (location.pathname === '/dashboard') {
            setIsDashboard(true);
        } else {
            setIsDashboard(false);
        }
    }, [location.pathname])
    return (
        <div className='topbar'>
            <div className="container-fluid">
                <div className="row">
                    <div className='col-lg-8 '>
                        <div className=" ">
                            <h1 className='H1'></h1>
                        </div>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-end">
                        <Dropdown className='lh-1' >
                            <Dropdown.Toggle variant="light" id="user-dropdown">
                                <FaUser />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {Isdashboard ? 
                                (<Dropdown.Item ><Link className='brlink' id="drhlink" to={'/'}>HomePage</Link></Dropdown.Item>) 
                                :
                                 (
                                    <Dropdown.Item ><Link className='brlink' id="drhlink" to={'/profile'}>Profile</Link></Dropdown.Item>
                                )}<br />
                                <Dropdown.Item onClick={handellogout} id="drhlink">Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default Topbar;
