import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { FaSearch, FaPen, FaTrash } from "react-icons/fa";
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import CoursecategoryUpdated from './CoursecategoryUpdate';

const CourseCategory = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addForm, setAddForm] = useState(false);
    const [input, setInput] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [formData, setFormData] = useState(null);

    const userPerPage = 5;

    const handleUpdate = (item) => {
        setAddForm(!addForm);   
        setFormData(item); 
    };

    const handleAdd = async (formDataToSend) => {
        try {
            const res = await axios.post(`https://sdmipl.com/portal/api/internship/category-create`, formDataToSend);
            console.log('Category create:', res);
            handleUpdate(null);
            Swal.fire({
                icon: 'success',
                title: 'Successfully',
                text: 'Category create successfully!',
            });
            fetchData();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Server error, please try again.',
            });
            console.error('Error storing category:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [addForm]);

    const fetchData = async () => {
        try {
            const response = await axios.get("https://sdmipl.com/portal/api/internship/category-view");

            console.log('fetchcategory', response.data)

            setData(response.data.records);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.post(`https://sdmipl.com/portal/api/internship/category-delete/${id}`);
            const newData = data.filter(item => item.id !== id);
            setData(newData);

            Swal.fire({
                icon: 'success',
                title: "Successfully Deleted",
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error('There was a problem with your delete operation:', error);
        }
    };

    const filteredData = data.filter(item =>
        item.title.toLowerCase().includes(input.toLowerCase())
    );
    const pageCount = Math.ceil(filteredData.length / userPerPage);
    const offset = pageNumber * userPerPage;
    const endIndex = Math.min(offset + userPerPage, filteredData.length);

    const startIndex = offset;
    
    const displayedData = filteredData.slice(startIndex, endIndex);

    if (loading) {
        return (
            <div className='container'>
                <div className='row p-5'>
                    <LinearProgress />
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid upshowing">
            <form className="row ">
                {addForm ? (
                    <CoursecategoryUpdated handleUpdate={handleUpdate} formData={formData} handleSubmit={handleAdd} />

                ) :
                    <div className="row slider_page">
                        <div className=" ">
                            <div className='card card_design p-3 mt-3'>
                                <div className="col-lg-12">
                                    <nav aria-label=" breadcrumb ">
                                        <ol className="breadcrumb  p-3">
                                            <li className="breadcrumb-item "><Link to="/general-setting" className='brlink '>Home</Link></li>
                                            <li className="breadcrumb-item" aria-current="page">All Course Category</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-lg-12 d-flex justify-content-end">
                                <div className='row align-items-center '>
                                    <div className=' upbtn d-flex justify-content-end'>
                                        <button onClick={() => handleUpdate(null)}>Add</button>

                                    </div>
                                </div>
                                <div className="row g-3 p-3">
                                    <div className=" col-lg-10 p-3 m-auto">
                                        <label htmlFor="search" className="visually-hidden">search</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" id="search" placeholder="Search here" onChange={(e) => setInput(e.target.value)} />
                                            <FaSearch id='srh-icon' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-light table-responsive">
                            <Table className="table table-striped table-hover">
                                <Thead>
                                    <Tr>
                                        <Th scope="col">#</Th>
                                        <Th scope="col">Title</Th>
                                        <Th scope="col">Description</Th>
                                        <Th scope="col">Image</Th>
                                        <Th scope="col">Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {displayedData.length > 0 ? (
                                        filteredData.slice(offset, offset + userPerPage).map((item, index) => (
                                            <Tr key={index}>
                                                <Td >{index + 1}</Td>
                                                <Td>{item.title}</Td>
                                                <Td>{item.description}</Td>
                                                <Td> <img src={item.image} alt='img' /> </Td>
                                                <Td className='action'>
                                                    <button className='btn btn-primary me-2' onClick={() => handleUpdate(item)} >Edit</button>
                                                    <button className='btn btn-danger me-2' onClick={() => handleDelete(item.id)}>Delete</button>
                                                </Td>
                                            </Tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <Td colSpan="5">No data Found...</Td>
                                        </tr>
                                    )}
                                </Tbody>
                            </Table>
                        </div>
                        <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                            <ReactPaginate
                                nextLabel={<>Next </>}
                                pageCount={pageCount}
                                onPageChange={(selectedPage) => setPageNumber(selectedPage.selected)}
                                disabledClassName={'pagination__link--disabled'}
                                previousLabel="Previous"
                                containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                                breakClassName='page-item'
                                breakLinkClassName='page-link'
                                previousClassName='page-item'
                                previousLinkClassName='page-link'
                                nextClassName='page-item'
                                nextLinkClassName='page-link'
                                activeClassName='active  pb-3'
                                pageClassName='page-item ml-1 mr-1'
                                pageLinkClassName='page-link'
                            />
                        </div>
                    </div>
                }
            </form>
        </div>
    );
};

export default CourseCategory;
