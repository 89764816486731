import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { FaSearch } from "react-icons/fa";
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Link } from 'react-router-dom';
import Subscription_plans from './subscription_plans'
// import UpdateCoursepost from './updateCoursepost';
import { MdDelete } from "react-icons/md";
import { MdEditSquare } from "react-icons/md";

const Viewsubscription = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addForm, setAddForm] = useState(false);
    const [input, setInput] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [fileInput, setFileInput] = useState(null);
    const [formData, setFormData] = useState(null);
    const [editId, setEditId] = useState(null);
    const userPerPage = 10;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://sdmipl.com/portal/api/internship/subscription-view");
                setData(response.data.records);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [addForm]);

    const handleUpdate = (id) => {
        setEditId(id);
        const courseToUpdate = data.find(course => course.id === id);
        setFormData(courseToUpdate);
        setAddForm(true);
    };
    const handleEdit = (id) => {
        const planToEdit = data.find(plan => plan.id === id);
        setFormData(planToEdit);
        setAddForm(true);
    };
    const handleDelete = async (id) => {
        try {
            await axios.post(`https://sdmipl.com/portal/api/internship/subscription-delete/${id}`);
            const newData = data.filter(item => item.id !== id);
            setData(newData);

            Swal.fire({
                icon: 'success',
                title: "Successfully Deleted",
                showConfirmButton: false,
                timer: 2000
            });

        } catch (error) {
            console.error('There was a problem with your delete operation:', error);
        }
    };

    const filteredData = data.filter(item =>
        item.title.toLowerCase().includes(input.toLowerCase())
    );

    const pageCount = Math.ceil(filteredData.length / userPerPage);
    const offset = pageNumber * userPerPage;
    const endIndex = Math.min(offset + userPerPage, filteredData.length);
    const startIndex = offset;

    const displayedData = filteredData.slice(startIndex, endIndex);
    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
    };

    const handleFileChange = (event) => {
        setFileInput(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        try {
            const formData = new FormData();
            formData.append('file', fileInput);

            const response = await axios.post("https://sdmipl.com/portal/api/upload-file", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    if (loading) {
        return (
            <div className='container'>
                <div className='row p-3 py-10'>
                    <LinearProgress />
                </div>
            </div>
        );
    }

    return (
        <div className="container upshowing">
            <div className="row">
                {!editId && !addForm && (

                    <div className="row slider_page">
                        <div className='card card_design p-3 mt-3'>
                            <div className="col-lg-12">
                                <nav aria-label=" breadcrumb ">
                                    <ol className="breadcrumb  p-3">
                                        <li className="breadcrumb-item "><Link to="/general-setting" className='brlink text-decoration-none '>Home</Link></li>
                                        <li className="breadcrumb-item" aria-current="page">Subscription Plan</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-lg-12 d-flex justify-content-end">
                            <div className='row align-items-center '>
                                <div className=' upbtn d-flex justify-content-end'>
                                    <button onClick={() => setAddForm(true)}>Add</button>
                                </div>
                            </div>
                            <div className="row g-3 p-3">
                                <div className=" col-lg-10 p-3 m-auto">
                                    <label htmlFor="search" className="visually-hidden">search</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="search" placeholder="Search here" onChange={(e) => setInput(e.target.value)} />
                                        <FaSearch id='srh-icon' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" shadow-sm  bg-light  table-responsive">
                            <Table className="table table-striped table-hover">
                                <Thead>
                                    <Tr>
                                        <Th scope="col">#</Th>
                                        <Th scope="col">Title</Th>
                                        <Th scope="col">Overview</Th>
                                        <Th scope="col">Description</Th>
                                        <Th scope="col">Price</Th>
                                        <Th scope='col'>Duration</Th>
                                        <Th scope='col'>image</Th>
                                        <Th scope="col">Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {displayedData.length > 0 ? (
                                        displayedData.map((item, index) => (
                                            <Tr key={index}>
                                                <Td><div className='lineclamptbl'>{startIndex + index + 1}</div></Td>
                                                <Td><div className='lineclamptbl'>{item.title}</div></Td>
                                                <Td><div className='lineclamptbl'>{item.overview}</div></Td>
                                                <Td><div className='lineclamptbl'>{item.description}</div></Td>
                                                <Td><div className='lineclamptbl'>{item.price}</div></Td>
                                                <Td><div className='lineclamptbl'>{item.duration}</div></Td>
                                                <Td><div className='lineclamptbl'> <img src={item.image} alt='img' /> </div></Td>
                                                <Td className='action'>
                                                    <button className='btn editicn' onClick={() => handleUpdate(item.id)} ><MdEditSquare />
                                                    </button>
                                                    <button className='btn dlticn' onClick={() => handleDelete(item.id)}><MdDelete />
                                                    </button>
                                                </Td>
                                            </Tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <Td colSpan="4">No data Found...</Td>
                                        </tr>
                                    )}
                                </Tbody>
                            </Table>
                        </div>
                        <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                            <ReactPaginate
                                nextLabel={<>Next </>}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                disabledClassName={'pagination__link--disabled'}
                                previousLabel="Previous"
                                containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                                breakClassName='page-item'
                                breakLinkClassName='page-link'
                                previousClassName='page-item'
                                previousLinkClassName='page-link'
                                nextClassName='page-item'
                                nextLinkClassName='page-link'
                                activeClassName='active  pb-3'
                                pageClassName='page-item ml-1 mr-1'
                                pageLinkClassName='page-link'
                            />
                        </div>
                    </div>

                )}
                {addForm && (
                    <Subscription_plans handleUpdate={() => setAddForm(false)} />
                )}
                {editId && (
                    <Subscription_plans handleUpdate={() => setAddForm(false)} formData={data.find(item => item.id === editId)} />

                )}
            </div>
        </div>
    );
}

export default Viewsubscription;
