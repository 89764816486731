import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import sdmipl from './../images/sionlogo.webp';
const Payments = () => {
  const location = useLocation();
  const { id } = useParams();
  const internData = location.state?.courseData;
  const [data, setData] = useState({});
  const [subscription, setSubcrption] = useState([])

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    totalAmount: internData ? internData.price : '',
  });
  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get('https://sdmipl.com/portal/api/internship/subscription-view')
            const pricing = response.data.records;
            if (response.status === 200) {
                const data = pricing.find((item) => item.id == id && item)
                console.log("filter data fetch ", data)
                setSubcrption(data)

            } else {
                console.log("Api not responding")
            }
        }
        catch (err) {
            console.log('Something fetching Error', err);
        }
    }

    fetchData();
}, [id]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem('id');
        const response = await axios.get(`https://sdmipl.com/portal/api/login-single_record/${userId}`);
        const userData = response.data.records;
        setData(userData);
        setFormData(prevData => ({
          ...prevData,
          name: userData.name || '',
          email: userData.email || '',
          mobile: userData.mobile || userData.number || '',
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const options = {
      key: 'rzp_test_kcSW1LMmxw3sy4',
      amount: parseInt(formData.totalAmount|| subscription.price) * 100, 
      currency: 'INR',
      name: 'SDMIPL',
      description: 'Sdmipl Internship',
      image: sdmipl, 
      handler: function (response) {
        console.log('Payment success id:', response.razorpay_payment_id);
      },
      prefill: {
        name: formData.name,
        email: formData.email,
        contact: formData.mobile,
      },
      theme: {
        color: '#F7841C',
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  return (
  <>
    <div className="container d-flex justify-content-center mt-5">
      <div className="securedcard card p-4 shadow-lg">
        <h2 className="mb-4 text-center" style={{ color: '#07a291db' }}>
          SECURE PAYMENT
        </h2>
        <form onSubmit={handleSubmit} className="payment d-flex justify-content-center">
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Mobile"
                  name="mobile"
                  minLength={10}
                  maxLength={10}
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <input
                type="number"
                className="form-control"
                placeholder="Total Amount"
                name="totalAmount"
                value={formData.totalAmount || subscription.price }
                onChange={handleChange}
                disabled
                required
              />
            </div>
          </div>
          <button type="submit" className="registerbtn d-flex justify-content-center">
            Checkout
          </button>
        </form>
      </div>
    </div>
    </>
  );
};

export default Payments;
