import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Reactpaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { FaSearch } from "react-icons/fa";

const Users = () => {
  const [data, SetData] = useState("");
  const [input, Setinput] = useState("");
  const navigate = useNavigate();

  // react pagination 
  const [pageNumber, setPageNumber] = useState(0);
  const userPerPage = 7;
  const pageCount = Math.ceil(data.length / userPerPage);
  const offset = pageNumber * userPerPage;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://sdmipl.com/portal/api/internship/category-view");
        if (response.data.error === true) {
          console.warn("fatched");
          SetData(response.data.records);
        } else {
          console.warn("error === false");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const formData = { id: id }
    try {
      const response = await axios.post('https://sdmipl.com/portal/api/internship/category-delete', formData);

      if (response.data.error === true) {
        navigate("/view");

        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: 'success',
          title: "sucessfully Deleted",
        });

      } else {
        console.error("API Error");
      }
    } catch (error) {
      console.error('There was a problem with your delete operation:', error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="shadow">
          <div className="row">
            <div className="col-lg-6">
              <nav aria-label=" breadcrumb ">
                <ol className="breadcrumb  p-3">
                  <li className="breadcrumb-item "><a href="/" className='text-decoration-none '>Home</a></li>
                  <li className="breadcrumb-item" aria-current="page">Category View</li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6">
              <div className="row g-3 p-3">
                <div className=" col-lg-10 p-3 m-auto">
                  <label htmlFor="search" className="visually-hidden">Password</label>
                  <div className="input-group">
                    <input type="text" className="form-control" id="search" placeholder="Search here" onChange={(e) => Setinput(e.target.value)} />
                    <FaSearch id='srh-icon' />

                  </div>
                </div>

              </div>

            </div>
          </div>
          <table className="table table-striped table-hover text-center ">

            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Title</th>
                <th scope="col">Discription</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>

              {data && data
                .filter((item) =>
                  input.toLowerCase() === '' ? true : item.title.toLowerCase().includes(input.toLowerCase()))
                .slice(offset, offset + userPerPage)
                .map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.title}</td>
                    <td>{item.description}</td>
                    <td className=''>
                      <Link to={`/update/${item.id}`} className="me-2">
                        <button type='button' className='btn btn-success'>Update</button>
                      </Link>

                      <button type='button' className='btn btn-danger ms-2' onClick={(e) => { handleDelete(item.id) }}>Delete</button>
                    </td>

                  </tr>
                ))}
            </tbody>
          </table>
          <div className='pagination pagination-lg justify-content-center text-center mt-3'>
            <Reactpaginate
              nextLabel={<>Next </>}
              pageCount={pageCount}
              onPageChange={(selectedPage) => setPageNumber(selectedPage.selected)}
              disabledClassName={'pagination__link--disabled'}
              previousLabel="Previous"
              containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              activeClassName='active  pb-3'
              pageClassName='page-item ml-1 mr-1'  // Custom class for pagination item
              pageLinkClassName='page-link'  // Custom class for pagination link
            />
          </div>
        </div>
      </div>
    </div>

  )
}
export default Users;