import React from 'react'
import Footer from '../footer'
import Header from '../header'
import { Outlet } from 'react-router-dom'

function Layout5() {
  return (
    <>
    <Header />
    <Outlet />
    <Footer />
    </>
  )
}

export default Layout5 ;