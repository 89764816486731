import React from 'react';
import { Link } from 'react-router-dom';

function Joinnow() {
  return (
    <>
    <div className='container'>
        <div className='row'>
          <div className='mainjn rounded mt-4 d-flex text-align-center justify-content-center align-items-center'>
            <div className='col-lg-9 text-center py-5'>
                <h1 className='text-white py-4'>Join us today without any hesitation</h1>
                <p className=' text-center text-white'>Unlock Your Coding Potential with Our Internship Program!
                Are you ready to take your coding skills to the next level? Join our hands-on internship program designed to equip you with real-world experience and industry-relevant skills. Whether you're a beginner or looking to enhance your expertise, our program offers personalized mentorship, collaborative projects, and an opportunity to build a portfolio that stands out. Don't miss out on this chance to learn from the best and kickstart your career in tech. Click 'Register Now' to secure your spot or 'Login' to continue your journey with us. Join us today and turn your passion for coding into a professional success story!</p>
            <Link to="/login"><button className='joinnow'>Join Now</button></Link> 
            <Link to="/registration" ><button className='joinnow'>Register Now</button></Link>
            </div>
          </div>
        </div>
    </div>
    </>
  )
}
export default Joinnow;