import React from 'react';
import { FaAtlassian } from "react-icons/fa6";
import AliceCarousel, { Link } from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import JavaScriptImg from "../images/jscript.png";
import BootstrapImg from "../images/bootstrap.jpeg";
import CssImg from "../images/css.png";
import TailwindImg from "../images/tailwind.webp";
import JqueryImg from "../images/jquery.png";
import ReactImg from "../images/React.png";
import Next from "../images/next.png";
const Features = () => {
  const items = [
    [
      <img className='gallery-image' src={JavaScriptImg} alt="JavaScript" />,
      <img className='gallery-image' src={BootstrapImg} alt="Bootstrap" />,
      <img className='gallery-image' src={CssImg} alt="CSS" />,
      <img className='gallery-image' src={ReactImg} alt="ReactImg" />,
      <img className='gallery-image' src={Next} alt="Next" />,
    ],
    [
      <img className='gallery-image' src={TailwindImg} alt="Tailwind" />,
      <img className='gallery-image' src={JqueryImg} alt="jQuery" />,
      <img className='gallery-image' src={JqueryImg} alt="jQuery" />,
      <img className='gallery-image' src={ReactImg} alt="ReactImg" />,
      <img className='gallery-image' src={Next} alt="Next" />,

    ],
    [
      <img className='gallery-image' src={JavaScriptImg} alt="JavaScript" />,
      <img className='gallery-image' src={BootstrapImg} alt="Bootstrap" />,
      <img className='gallery-image' src={CssImg} alt="CSS" />,
      <img className='gallery-image' src={ReactImg} alt="ReactImg" />,
      <img className='gallery-image' src={Next} alt="Next" />,

    ],
  ];

  return (
    <>
      <div className='wave-main'>
      <svg xmlns="http://www.w3.org/2000/svg"  className='animationwave' viewBox="0 0 1440 320" >
        <path fill="#0099ff" fill-opacity="1"  d="M0,256L24,250.7C48,245,96,235,144,213.3C192,192,240,160,288,133.3C336,107,384,85,432,112C480,139,528,213,576,234.7C624,256,672,224,720,192C768,160,816,128,864,138.7C912,149,960,203,1008,213.3C1056,224,1104,192,1152,170.7C1200,149,1248,139,1296,138.7C1344,139,1392,149,1416,154.7L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path> </svg>
        <div className="wave-display">
          <ol className="breadcrumb">
            <li className="breadcrumb-item "><Link to="/" className='brlink'><b>Home</b></Link></li>
            <li className="breadcrumb-item" aria-current="page"><Link to="#"><b className='text-light

'>Features</b></Link></li>
          </ol>
        </div>
        </div>
    <div className='container wavi'>
      <div className='feture'>
        <div className='fmain'>
          <h3 className='fhding'>"Intern with Confidence: Trusted by Leading Brands"?</h3>
        </div>
        <div className='carsousel'>
        <AliceCarousel 
          mouseTracking
          items={items}
          autoPlay 
          autoPlayInterval={2000} 
        />
        </div>
        <div className='fcontent'>
           Everything you need to find, hire, and manage talents
        </div>
        <div className="features-second">
              <div className="row feature">
                <div className="col-lg-6 col-md-6 col-sm-12 mt-3 ">
                  <div className="sponline">
                  <h2> <strong>Why Choose Internship Mode?</strong></h2> 
                  <strong>Comprehensive Notes:</strong>  Access detailed course notes covering every topic, ensuring you have a thorough understanding of the material.<br />
                  <strong>Online and Offline Classes: </strong> Enjoy the flexibility of attending classes either online or offline, allowing you to learn in a way that suits your schedule.
                    <br />
                    <strong>Real-world Application:</strong> Throughout the course, you'll work on projects designed to simulate real-world scenarios commonly encountered in web development.
                    <br />
                    <strong><FaAtlassian /> </strong>Projects will cover a range of topics including front-end development (HTML, CSS, JavaScript), back-end development (Node.js, Python, databases), and full-stack development.
                    <br />
                    <strong>Resume Boost:</strong> Upon successful completion of the course, you'll receive a certificate of completion from our internship program, highlighting your hands-on experience and skills acquired.
                    <br />
                    <strong><FaAtlassian /> </strong> The certificate will serve as a valuable addition to your resume, demonstrating your proficiency in web development and your commitment to gaining practical expertise.
                    <br />
                    <strong>Industry Recognition:</strong> We'll provide guidance on building a portfolio website that effectively demonstrates your projects, skills, and accomplishments.
                    <br />
                  <strong><FaAtlassian /> </strong>  We'll also offer workshops, seminars, and networking events to connect you with industry professionals and potential employers, helping you gain recognition and visibility in the web development community.
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="list">
                    <ul>
                      <li>Live Projects with Industry Partners</li>
                      <li>Mentorship Program</li>
                      <li> Networking Opportunities</li>
                      <li>Portfolio Enhancement</li>
                      <li>Flexible Timing</li>
                      <li>Certificate of Completion</li>
                      <li>Feedback and Evaluation</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>
    </>
  );
}

export default Features;
