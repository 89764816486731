import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import axios from 'axios';

function Exercise() {
    const [data, setData] = useState([]);
    const userId = localStorage.getItem("id");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://sdmipl.com/portal/api/internship/assignment-single_record/${userId}`);
                setData(response.data.records);
                console.log("assignment", response)
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [userId]);

    return (
        <>
            <div className='card card_design p-3 mt-3'>
                <div className="col-lg-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb p-3">
                            <li className="breadcrumb-item">
                                <Link to="/dashboard" className='brlink'>Home</Link>
                            </li>
                            <li className="breadcrumb-item" aria-current="page">Notes</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col">
                    <div className="card px-auto hit">
                        <div className="row align-items-center py-3">
                            <h3 className="heading">Lectures Notes</h3>
                            <div className="shadow-sm bg-light table-responsive">
                                {data.length ? (
                                    <Table className="table table-striped table-hover">
                                        <Thead>
                                            <Tr>
                                                <Th scope="col">#</Th>
                                                <Th scope="col">Title</Th>
                                                <Th scope="col">Description</Th>
                                                <Th scope="col">References</Th>
                                                <Th scope="col">Status</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {data.map((item, index) => (
                                                <Tr key={index}>
                                                    <Td>{index + 1}</Td>
                                                    <Td>{item.title}</Td>
                                                    <Td>{item.description}</Td>
                                                    <Td>{item.references}</Td>
                                                    <Td>{item.status}</Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                ) : (
                                    <p>No Assignment Found</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Exercise;
