import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function Fsubcategory() {
  const [coursedata, setCourseData] = useState([]);
  const [filterdata, setFilterData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://sdmipl.com/portal/api/internship/coursepost-view");
        setCourseData(response.data.records);
        console.log(response.data.records, 'headerfetching');
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const uniquedata = coursedata.filter(
      (item) =>
        item.category === "Frontend Course" ||
        item.category === "Frontend" ||
        item.category === "1"
    );
    setFilterData(uniquedata);
  }, [coursedata]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="subcateogory d-flex justify-content-between align-items-center">
          <div>
            <Link to="/ourfrontendcourses">
              <button className="Subbutton-main">
                <b>Frontend Developer</b>
              </button>
            </Link>

            <button className="clippath"></button>
    
              {filterdata && filterdata.length > 0 ? (
                filterdata.map((course, index) => (
                  <span key={index}>
                    <Link to={`/frontendCourse/${course.slug}`}>
                      <button className="subtn">{course.title}</button>
                    </Link>
                  </span>
                ))
              ) : (
                <span className="loader"></span>
              )}
      
            {/* <Link to="/html">
              <button className="subtn">HTML</button>
            </Link>
            <Link to="/css">
              <button className="subtn">CSS</button>
            </Link>
            <Link to="/javascript">
              <button className="subtn">JavaScript</button>
            </Link>
            <Link to="/jquery">
              <button className="subtn">JQuery</button>
            </Link>
            <Link to="/react">
              <button className="subtn">React Js</button>
            </Link>
            <Link to="/nextjs">
              <button className="subtn">Next Js</button>
            </Link>
            <Link to="/bootstrap">
              <button className="subtn">Bootstrap</button>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fsubcategory;
