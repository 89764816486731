import React, { useState } from 'react';
import contactimg from '../images/contactimg-removebg-preview.png';
import { Link } from 'react-router-dom';
import { LuPhoneCall } from 'react-icons/lu';
import { IoIosMailUnread } from 'react-icons/io';
import { IoIosMail } from 'react-icons/io';
import { BiMessageRoundedDetail } from 'react-icons/bi';
import { MdLocationPin } from 'react-icons/md';
import { BsPersonCircle } from 'react-icons/bs';
import axios from 'axios';
import Swal from 'sweetalert2';

const Contactus = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    massage: '',
  });

  const handelChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://sdmipl.com/portal/api/internship/massage-create', formData);
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: 'success',
        title: 'Message Sent Successfully',
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Try Again....',
        text: 'Something Wrong',
      });
      console.error('Error submitting form:', err);
    }
  };

  return (
    <>
      <div className='wave-main'>
        <svg xmlns="http://www.w3.org/2000/svg" className='animationwave' viewBox="0 0 1440 320" >
          <path fill="#0099ff" fill-opacity="1" d="M0,256L24,250.7C48,245,96,235,144,213.3C192,192,240,160,288,133.3C336,107,384,85,432,112C480,139,528,213,576,234.7C624,256,672,224,720,192C768,160,816,128,864,138.7C912,149,960,203,1008,213.3C1056,224,1104,192,1152,170.7C1200,149,1248,139,1296,138.7C1344,139,1392,149,1416,154.7L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path> </svg>
        <div className="wave-display">
          <ol className="breadcrumb">
            <li className="breadcrumb-item "><Link to="/" className='brlink'><b>Home</b></Link></li>
            <li className="breadcrumb-item" aria-current="page"><Link to='#'><b>Contact Us</b></Link></li>
          </ol>
        </div>
      </div>
      <div className="container wavi">
        <div className="row cfrm">
          <h3 className='text-center'>
            <strong >Get In touch</strong>
          </h3>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img className="img-fluid" src={contactimg} alt="Contact" />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <h4>Ready to get started?</h4>
            <div className="contact_form">
              <form className="login-signup-form" id='' onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="rgform">Name</label>
                  <div className="input-group input-group-merge">
                    <div className="input-icon">
                      <BsPersonCircle />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                      name="name"
                      onChange={handelChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="rgform">Email</label>
                  <div className="input-group input-group-merge">
                    <div className="input-icon">
                      <IoIosMail />
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email id"
                      name="email"
                      onChange={handelChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="rgform">Message</label>
                  <div className="input-group input-group-merge">
                    <div className="input-icon" id='mesgeicn'>
                      <BiMessageRoundedDetail />
                    </div>
                    <textarea
                      type="text-area"
                      name="massage"
                      className="form-control"
                      onChange={handelChange}
                      placeholder="Write Something Here..."
                      rows={5}
                      cols={25}
                      required
                    />
                  </div>
                </div>
                <button className="registerbtn mt-2" type="submit">Message</button>
              </form>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="text-center cntsdow">
              <div className="card-body py-4">
                <div className="iconcontact">
                  <LuPhoneCall />
                </div>
                <div>
                  <p className="contacttext">Office Phone</p>
                  <Link className="dtcontact" to="tel:+91 7880136882 ">
                    {' '}
                    +91 7880136882{' '}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="text-center cntsdow">
              <div className="card-body py-4">
                <div className="iconcontact">
                  <IoIosMailUnread />
                </div>
                <div>
                  <p className="contacttext">Mail Us</p>
                  <p className="text-muted"></p>
                  <Link
                    className="dtcontact my-3"
                    to="mailto:siondatamaticsindiapvtltd@gmail.com"
                  >
                    siondatamaticsindia@gmail.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 ">
            <div className="text-center cntsdow">
              <div className="card-body py-2">
                <div className="iconcontact">
                  <MdLocationPin />
                </div>
                <div>
                  <p className="contacttext">Office Address</p>
                  <p className="dtcontact  py-1">
                    Sion Datamatics India Pvt. Ltd. 5th Floor, RN- 505, 569,
                    Mahatma Gandhi Rd, Opposite High Court, Indore, Madhya
                    Pradesh
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className="google-map mb-n2 opacity-9 w-100 mt-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7360.39396994655!2d75.87340970000001!3d22.7209186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1715155314994!5m2!1sen!2sin"
            height="350"
            title="Google Maps"
          ></iframe>
        </div>
    </>
  );
};

export default Contactus;
