import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { FaBackward } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import RichText from '../../Subpages/RichTexteditor';

const UpdateCoursepost = ({ handleUpdate, formData }) => {
    const [formState, setFormState] = useState(formData || {
        title: '',
        category: '',
        overview: '',
        internship_mode: '',
        curriculum: '',
        features: '',
        price: '',
        duration: '',
        image: null,
        image_upload: null,
        banner_upload: null,
    });
    console.log(formData)
    const navigate = useNavigate();
    const baseUrl = "https://sdmipl.com/portal/public/image/"; 
    useEffect(() => {
        setFormState(formData || {
            title: '',
            category: '',
            overview: '',
            internship_mode: '',
            curriculum: '',
            features: '',
            price: '',
            duration: '',
            image: null,
            image_upload: null,
            banner_upload: null,
        });
    }, [formData]);
  console.log(formState);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...formState,
            [name]: value,
        });
    };
  
    const handleRichTextChange = (name,value) => {
        setFormState({
            ...formState,
            [name]: value,
        });
    };

    const handleImageChange = (e, name) => {
        const file = e.target.files[0];
        setFormState({
            ...formState,
            [name]: file,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            Object.entries(formState).forEach(([key, value]) => {
                if (value !== null) {
                    formData.append(key, value);
                }
            });
            const res = await axios.post(`https://sdmipl.com/portal/api/internship/coursepost-update/${formState.id}`, formData);
            console.log('CoursepostUpdate', res);
            Swal.fire({
                icon: "success",
                title: "Successfully",
                text: 'Coursepost updated successfully!',
            });
            setFormState({
                ...formState,
                image: res.data.image,
                image_upload: res.data.image_upload,
                banner_upload: res.data.banner_upload,
            });
            handleUpdate();
            console.log(res.data.banner_upload,)
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Try Again....",
                text: 'Server error, not responding',
            });
            console.log(error, 'something is wrong');
        }
    };

    const handleBack = () => {
        navigate('/admin-dashboard');
    };

    return (
        <div className='container comapnay_form'>
            <div className='card card_design p-3 mt-3'>
                <div className="col-lg-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb  p-3">
                            <li className="breadcrumb-item"><Link to="/general-setting" className='brlink '>Home</Link></li>
                            <li className="breadcrumb-item" aria-current="page">Add Course Post</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='upbtn'>
                <button onClick={handleBack}><FaBackward /> Back</button>
            </div>
            <div className="row mt-2">
                <div className="col-lg-12 mt-5">
                    <div className='registerWrapper'>
                        <div className="twm-tabs-style-2">
                            <form className="row align-items-center" onSubmit={handleSubmit} encType='multipart/form-data'>
                                <div className='category-container'>
                                    <h4>Add Course Post</h4>
                                    <button type="submit" className="site-button">Update Course</button>
                                </div>
                                 <hr/>
                                <div className="col-lg-4">
                                    <div className='imgaddmain'>
                                        <div className='pic'>
                                            <label htmlFor="image" className="form-label">Image</label>
                                            {formState.image && (
                                                <img src={`${baseUrl}${formState.image}`} alt="Course Image" />
                                            )}
                                            <input type="file" name="image" onChange={(e) => handleImageChange(e, 'image')} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className='imgaddmain'>
                                        <div className='pic'>
                                            <label htmlFor="image_upload" className="form-label">Image_upload</label>
                                            {formState.image_upload && (
                                                <img src={`${baseUrl}${formState.image_upload}`} alt="Course Image" />
                                            )}
                                            <input type="file" name="image_upload" onChange={(e) => handleImageChange(e, 'image_upload')} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className='imgaddmain'>
                                        <div className='pic'>
                                            <label htmlFor="banner_upload" className="form-label">banner_upload</label>
                                            {formState.banner_upload && (
                                                <img src={`${baseUrl}${formState.banner_upload}`} alt="Course Image" />
                                            )}
                                            <input type="file" name="banner_upload" onChange={(e) => handleImageChange(e, 'banner_upload')} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                               <hr  className='hrupdateimg '/>
                                <div className="col-lg-6 mt-1">
                                    <label htmlFor="course_title" className="form-label">Course title</label>
                                    <input type='text' name="title" placeholder={"Course Title"} value={formState.title} onChange={handleChange} className='form-control' />
                                </div>
                                <div className="col-lg-6 mt-1">
                                    <div className="form-group ">
                                        <label className='form-label' htmlFor='category'>Course Category</label>
                                        <select name="category" className="form-control form-select" value={formState.category} onChange={handleChange} >
                                            <option value=''>Select Course Category</option>
                                            <option value={1}>Frontend</option>
                                            <option value={2}>Backend</option>
                                            <option value={3}>Full Stack</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="form-group mb-3">
                                        <label className='form-label' htmlFor='internship_mode'>Internship Mode</label>
                                        <select name="internship_mode" className="form-control form-select" value={formState.internship_mode} onChange={handleChange} >
                                            <option value=''>Select Internship Mode</option>
                                            <option value='Online-Mode'>Online-Mode</option>
                                            <option value='Offline-Mode'>Offline-Mode</option>
                                            <option value='Hybrid'>Hybrid</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="price" className="form-label">Course Price</label>
                                    <input type='text' name="price" placeholder={"Course Price"} value={formState.price} onChange={handleChange} className='form-control' />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="curriculum" className="form-label">Curriculum</label>
                                    <textarea type='text-area' name="curriculum" placeholder={"Curriculum of Course"} value={formState.curriculum} onChange={handleChange} className='form-control' rows={4} />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="duration" className="form-label">Duration</label>
                                    <textarea type='text-area' name="duration" placeholder={"Duration of Course"} value={formState.duration} onChange={handleChange} className='form-control' rows={4} />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="features" className="form-label">Features</label>
                                    <textarea type='text-area' name="features" placeholder={"Features of Course"} value={formState.features} onChange={handleChange} className='form-control' rows={4} />
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="overview" className="form-label">Overview</label>
                                    <RichText name="overview" placeholder={"overview"} value={formState.overview} onChange={handleRichTextChange} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default UpdateCoursepost;
