import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import gif from '../images/computer-security-with-login-password-padlock.jpg'

function Popup() {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>

            <Modal show={show} onHide={handleClose} animation={false}>
                <div className="container">
                    <div className="row ">
                        <div className="col-6 popupdegn"><img className='licn' src={gif} /> </div>
                        <div className="col-6 popupdegn">
                            <p className='popctnt'>You don't have login please login first</p>
                            <div className='jnbtn d-flex'>
                            <Link className='btn ' id="vbtn" to="/login"  >
                                Login
                            </Link>

                            <Link className='btn 'id="vbtn" to="/"    onClick={handleClose}>
                                Back
                            </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Popup;