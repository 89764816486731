import React from 'react'
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

function Upcominglecture() {
    return (
        <>
            <div className='card card_design p-3 mt-3'>
                <div className="col-lg-12">
                    <nav aria-label=" breadcrumb ">
                        <ol className="breadcrumb  p-3">
                            <li className="breadcrumb-item ">
                                <Link to="/dashboard" className='brlink '>Home</Link></li>
                            <li className="breadcrumb-item" aria-current="page">Upcoming - Lectures</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col">
                    <div className=" card px-auto hit">
                        <div className="row align-items-center py-3">
                            <h3 className="heading">Upcoming Lectures</h3>
                            <div className=" shadow-sm  bg-light  table-responsive">
                                <Table className="table table-striped table-hover">
                                    <Thead>
                                        <Tr>
                                            <Th scope="col">Topic</Th>
                                            <Th scope="col">Category</Th>
                                            <Th scope="col">Links</Th>
                                            <Th scope="col">Date</Th>
                                            <Th scope="col">Duration</Th>
                                            <Th scope="col">Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                      
                                                <Tr>
                                                    <Td >Element tags</Td>
                                                     <Td> Html </Td>
                                                     <Td>www.sdmipl.com</Td>
                                                     <Td>26/04/2024</Td>
                                                     <Td>2</Td>
                                                     <Td>View</Td>
                                                </Tr>
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Upcominglecture;