
import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import RichText from '../../Subpages/RichTexteditor';
import { Link } from 'react-router-dom';

const AddCoursepost = ({ handleUpdate, initialFormData }) => {
    const [formData, setFormData] = useState(() => {
        return initialFormData || {
            title: '',
            category: '',
            overview: '',
            internship_mode: '',
            curriculum: '',
            features: '',
            price: '',
            duration: '',
            video:null,
            image_upload: null,
            banner_upload: null,
            image: null
        };
    });

    const handleChange = (e, name) => {
        if (name === 'image_upload' || name === 'banner_upload' || name === 'image') {
            const file = e.target.files[0];

            setFormData({
                ...formData,
                [name]: file || formData[name],
            });
        } else {
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("form", formData)
        try {
            const Formdatasend = new FormData();
            Formdatasend.append('title', formData.title);
            Formdatasend.append('category', formData.category);
            Formdatasend.append('overview', formData.overview);
            Formdatasend.append('internship_mode', formData.internship_mode);
            Formdatasend.append('curriculum', formData.curriculum);
            Formdatasend.append('features', formData.features);
            Formdatasend.append('price', formData.price);
            Formdatasend.append('duration', formData.duration);
            if (formData.image && typeof formData.image !== 'string') {
                Formdatasend.append('image', formData.image);
            }
            if (formData.banner_upload && typeof formData.banner_upload !== 'string') {
                Formdatasend.append('banner_upload', formData.banner_upload);
            }
            if (formData.image_upload && typeof formData.image_upload !== 'string') {
                Formdatasend.append('image_upload', formData.image_upload);
            }
            if (formData.video && typeof formData.video !== 'string') {
                Formdatasend.append('video', formData.video);
            }
            const res = await axios.post('https://sdmipl.com/portal/api/internship/coursepost-create', Formdatasend);
            console.log('course', res);
            Swal.fire({
                icon: "success",
                title: "Successfully",
                text: ' Course Add Successfully!! ',
            });

            handleUpdate();
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Try Again....",
                text: 'Server error, not responding',
            });
            console.log(error, 'something is wrong');
        }
    };
    return (
        <div className='container comapnay_form'>
            <div className='card card_design p-3 mt-3'>
                <div className="col-lg-12">
                    <nav aria-label=" breadcrumb ">
                        <ol className="breadcrumb  p-3">
                            <li className="breadcrumb-item "><Link to="/general-setting" className='brlink '>Home</Link></li>
                            <li className="breadcrumb-item" aria-current="page">Add Course Post</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='upbtn'>
                <button onClick={handleUpdate}>Back</button>
            </div>
            <div className="row mt-2">
                <div className="col-lg-12 mt-5">
                    <div className='registerWrapper'>
                        <div className="twm-tabs-style-2">
                            <form className="row align-items-center" onSubmit={handleSubmit} encType='multipart/form-data'>
                                <div className='category-container'>
                                    <h4>Add Course Post</h4>
                                    <button type="submit" className="site-button" >
                                        Add Course Post
                                    </button>
                                </div>
                                <div className='heading'>Add Images</div>

                                <div className='col-lg-4'>
                                    <div className='imgaddmain'>
                                        <div className='pic'>
                                            {formData.image_upload ? (
                                                <img src={formData.image_upload instanceof File ? URL.createObjectURL(formData.image_upload) : formData.image_upload} alt="Selected" />

                                            ) : (
                                                <input type="file" accept="image/*" name='image_upload' className='form-control' onChange={(e) => handleChange(e, 'image_upload')} />

                                            )}
                                            <h6 className='mt-2 text-center'>Course image</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='imgaddmain'>
                                        <div className='pic'>
                                            {formData.banner_upload ? (
                                                <img src={formData.banner_upload instanceof File ? URL.createObjectURL(formData.banner_upload) : formData.banner_upload} alt="Selected" />
                                            ) : (
                                                <input type="file" accept="image/*" name='banner_upload' className='form-control' onChange={(e) => handleChange(e, 'banner_upload')} />
                                            )}
                                            <h6 className='mt-2 text-center'>Banner image</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='imgaddmain'>
                                        <div className='pic'>
                                            {formData.image ? (
                                                <img src={formData.image instanceof File ? URL.createObjectURL(formData.image) : formData.image} alt="Selected" />
                                            ) : (
                                                <input type="file" accept="image/*" name='image' className='form-control' onChange={(e) => handleChange(e, 'image')} />
                                            )}
                                            <h6 className='mt-2 text-center'>Image</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 mt-5">
                                    <label htmlFor="course_title" className="form-label"> Course Title</label>
                                    <input
                                        type='text'
                                        name="title"
                                        placeholder={"Course Title"}
                                        onChange={handleChange}
                                        className='form-control'
                                    />
                                </div>
                                <div className="col-lg-6 mt-5">
                                    <div className="form-group ">
                                        <label className='form-label' htmlFor='category'>Course Category</label>
                                        <select
                                            name="category"
                                            className="form-control form-select" onChange={handleChange}
                                        >
                                            <option value=''>Select Course Category</option>
                                            <option value={"Frontend Course"}>Frontend Course</option>
                                            <option value={"Backend Course"}>Backend Course</option>
                                            <option value={"UI/UX Course"}>UI/UX Course</option>

                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-6 ">
                                    <div className="form-group mb-3">
                                        <label className='form-label' htmlFor='internship_mode'> Internship Mode</label>
                                        <select
                                            name="internship_mode"
                                            className="form-control form-select"
                                            onChange={handleChange}
                                        >
                                            <option value=''>Select Internship Mode</option>
                                            <option>
                                                Online-Mode
                                            </option>
                                            <option >
                                                Offline-Mode
                                            </option>
                                            <option>
                                                Hybird
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="form-group mb-3">
                                        <label className='form-label' htmlFor='duration'> Duration</label>
                                        <select
                                            name="duration"
                                            className="form-control form-select"
                                            onChange={handleChange}
                                        >
                                            <option value=''>Select Duration</option>
                                            <option>
                                            3 Month
                                            </option>
                                            <option >
                                            6 Month
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="price" className="form-label">Course Price</label>
                                    <input
                                        type='text'
                                        name="price"
                                        placeholder={"Course Price"}
                                        onChange={handleChange}
                                        className='form-control'
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="video" className="form-label">Course Video</label>
                                    <input
                                        type='file'
                                        name="video"
                                        onChange={(e) => handleChange(e, 'video')}
                                        className='form-control'
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="overview" className="form-label"> Overview </label>
                                    <RichText
                                        name="overview"
                                        placeholder={"description"}
                                        onChange={handleRichTextChange}
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="Curriculum" className="form-label"> Curriculum </label>
                                    <RichText
                                        name="curriculum"
                                        placeholder={"Curriculum of Course"}
                                        onChange={handleRichTextChange}
                                        className='form-control'
                                        rows={4}
                                    />
                                </div>
                              
                                <div className="col-lg-12">
                                    <label htmlFor="features" className="form-label"> Features </label>
                                    <RichText
                                        name="features"
                                        placeholder={"Features of Course"}
                                        onChange={handleRichTextChange}
                                        className='form-control'
                                        rows={4}
                                    />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddCoursepost;
