import React, { useEffect, useState } from "react";
import design from "../images/design.jpg";
import webdevelopment from '../images/webdevelopment.webp';
import { Link } from "react-router-dom";
import { PiArrowSquareDownRightFill } from "react-icons/pi";
import axios from "axios";

const baseUrl = "https://sdmipl.com/portal/public/image/";
function Design() {
  const [coursedata, setCourseData] = useState([]);
  const [filterdata, setFilterData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://sdmipl.com/portal/api/internship/coursepost-view");
        setCourseData(response.data.records);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const uniquedata = coursedata.filter(
      (item) =>
        item.category ===
        "UI/UX Course" || item.category === "3" || item.category === "design"
    );
    setFilterData(uniquedata);
  }, [coursedata]);
  return (
    <>
      <div className="training-section">
        <h1>Ui/Ux Training Courses</h1>
        <div className="container allcards">
          <div className="row">
            {filterdata && filterdata.length > 0 ? (

              filterdata.map((course, index) => (
                <div className="col-lg-4 col-md-6 col-sm-12" key={index}>

                  <div className="traning-items">
                    {course.image ? (
                      // <img src={`${baseUrl}${course.image}`} alt={course.title} />
                      <img src={course.image} alt={course.title} />

                    ) : (
                      <img className="rounded-circle" src={webdevelopment} alt="Web Development Course" />
                    )}
                    <h3>{course.title ? course.title : 'Course'}</h3>
                    <p className="crdsoverview">{course.overview}</p>
                    <text>
                      Mode: <span>{course.internship_mode}</span>
                    </text>
                    <div className="cbtn">
                      <div className="Cdiv">
                        <small>Duration:</small>
                        <p>{course.duration}</p>
                      </div>
                      <div className="Cdiv">
                        <small>Price :</small>
                        <p>{course.price}</p>
                      </div>
                      <div className="jnbtn">
                        <Link to={`/uiuxCourse/${course.slug}`}>
                          <button id="vbtn">View Details</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
 <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="loader_detail d-flex justify-content-center">
                  <span  className="loader"></span>
                </div>
                </div>
                
            </>
            )}
          </div>
        </div>
        <div className="container">
          <h3>Design Course Features</h3>
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <img src={design} alt="backend" className="img-fluid" />
            </div>
            <div className="col-lg-8 col-md-6 col-sm-12">
              <div className="features-list ">
                <ul>
                  <li>
                    <PiArrowSquareDownRightFill /> A program designed by
                    Subject-Matter
                    {/* Experts */}
                  </li>
                  <li>
                    <PiArrowSquareDownRightFill /> ₹21 Lakhs Highest Salary
                  </li>
                  <li>
                    <PiArrowSquareDownRightFill /> 100% Job Placement Support
                  </li>
                  <li>
                    <PiArrowSquareDownRightFill /> EMI options available
                  </li>
                  <li>
                    <PiArrowSquareDownRightFill /> LIVE classes + Lifetime
                    recorded videos
                  </li>
                  <li>
                    <PiArrowSquareDownRightFill /> 175% Highest Hike
                  </li>
                  <li>
                    <PiArrowSquareDownRightFill /> NASSCOM approved program with
                    {/* Government-approved Certification */}
                  </li>
                  <li>
                    <PiArrowSquareDownRightFill /> 500+ Hiring Partners
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Design;
