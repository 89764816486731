import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { FaSearch } from "react-icons/fa";
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Link } from 'react-router-dom';
import { MdDelete } from "react-icons/md";
const Interns_record = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addForm, setAddForm] = useState(false);
    const [selectid, setSelectid] = useState(false);
    const [input, setInput] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [fileInput, setFileInput] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        id: '',
        description: '',
        references: '',
        status: ''
    });
    const userPerPage = 10;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://sdmipl.com/portal/api/register-view");
                setData(response.data.records);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [addForm]);

    const pageCount = Math.ceil(data.length / userPerPage);
    const offset = pageNumber * userPerPage;
    const currentPageData = data.slice(offset, offset + userPerPage);

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
    };

    const handleUpdate = (id) => {
        setAddForm(!addForm);
    };

    const handleDelete = async (id) => {
        try {
            await axios.post(`https://sdmipl.com/portal/api/register-delete/${id}`);
            const newData = data.filter(item => item.id !== id);
            setData(newData);

            Swal.fire({
                icon: 'success',
                title: "Successfully Deleted",
                showConfirmButton: false,
                timer: 2000
            });

        } catch (error) {
            console.error('There was a problem with your delete operation:', error);
        }
    };

    const filteredData = data.filter(item =>
        item.title && item.title.toLowerCase().includes(input.toLowerCase())
    );

    const handleFileChange = (event) => {
        setFileInput(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        try {
            const formData = new FormData();
            formData.append('file', fileInput);

            // Make a POST request to upload the file
            const response = await axios.post("https://sdmipl.com/portal/api/upload-file", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // Handle the response
            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    if (loading) {
        return (
            <div className='container'>
                <div className='row p-3 py-10'>
                    <LinearProgress />
                </div>
            </div>
        );
    }

    return (
        <div className="container upshowing">
            <div className="row">

                <div className="row slider_page">

                    <div className='card card_design p-3 mt-3'>
                        <div className="col-lg-12">
                            <nav aria-label=" breadcrumb ">
                                <ol className="breadcrumb  p-3">
                                    <li className="breadcrumb-item "><Link to="/general-setting" className='brlink'>Home</Link></li>
                                    <li className="breadcrumb-item" aria-current="page">Interns Record</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-end">
                        <div className='row align-items-center '>
                            <div className=' upbtn d-flex justify-content-end'>
                                <button onClick={handleUpdate}>Add</button>
                            </div>
                        </div>
                        <div className="row g-3 p-3">
                            <div className=" col-lg-10 p-3 m-auto">
                                <label htmlFor="search" className="visually-hidden">search</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="search" placeholder="Search here" onChange={(e) => setInput(e.target.value)} />
                                    <FaSearch id='srh-icon' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="shadow-sm p-2 bg-light  table-responsive">
                        <Table className="table table-striped table-hover">
                            <Thead>
                                <Tr>
                                    <Th scope="col">#</Th>
                                    <Th scope="col">Name</Th>
                                    <Th scope="col">Email</Th>
                                    <Th scope="col">Contact Number</Th>
                                    <Th scope="col">Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {currentPageData.length > 0 ? (
                                    currentPageData.map((item, index) => (
                                        <Tr key={offset + index}>
                                            <Td>{offset + index + 1}</Td>
                                            <Td>{item.name}</Td>
                                            <Td>{item.email}</Td>
                                            <Td>{item.number || item.mobile}</Td>
                                            <Td className='action'>
                                                <select className='btn'>
                                                    <option> <Link to={`/registered_interns/${item.id}`} className='btn'>Active</Link></option>
                                                    <option>InActive</option>
                                                </select>
                                                <button className='btn dlticn' onClick={() => handleDelete(item.id)}><MdDelete /></button>
                                            </Td>
                                        </Tr>
                                    ))
                                ) : (
                                    <tr>
                                        <Td colSpan="5">No data Found...</Td>
                                    </tr>
                                )}
                            </Tbody>
                        </Table>
                    </div>
                    <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                        <ReactPaginate
                            nextLabel={<>Next </>}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            disabledClassName={'pagination__link--disabled'}
                            previousLabel="Previous"
                            containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                            breakClassName='page-item'
                            breakLinkClassName='page-link'
                            previousClassName='page-item'
                            previousLinkClassName='page-link'
                            nextClassName='page-item'
                            nextLinkClassName='page-link'
                            activeClassName='active  pb-3'
                            pageClassName='page-item ml-1 mr-1'
                            pageLinkClassName='page-link'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Interns_record;
