
import React, { useState } from "react";
import { createTheme, ThemeProvider, CssBaseline, Button } from "@mui/material";

function Theme() {
    const [toggleDark, settoggleDark] = useState(false);
    const lightPalette = {
        primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            main: '#ECFFDC ',
        },
        background: {
            default: '#000000',
        },
    };
    const darkPalette = {
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#f48fb1',
        },
        background: {
            default: '#000000',
        },
    };
    const myTheme = createTheme({
        palette: {
            mode: toggleDark ? "dark" : "light",
            ...(toggleDark ? darkPalette : lightPalette),
        },
    });

    const handleToggle = () => {
        settoggleDark(prev => !prev);
    };
    return (
        <ThemeProvider theme={myTheme}>
            <CssBaseline />
            <Button variant="contained"
                background-color="primary" onClick={handleToggle}>
                {toggleDark ? "Switch to Light Mode" : "Switch to Dark Mode"}
            </Button>
            <h1>hello , this is color mode</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum tempora libero commodi veritatis reprehenderit voluptatibus. Alias, atque! Soluta quibusdam ex, error libero expedita dolores saepe? Nisi asperiores nulla est similique vero adipisci, assumenda debitis nemo, eveniet cum natus beatae saepe temporibus itaque aperiam esse quo? Obcaecati quia veniam quam omnis blanditiis. Ratione neque cum at amet quaerat inventore voluptates facilis, similique expedita ut, nobis laboriosam quisquam magni.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non modi id facere ullam rem, impedit perspiciatis itaque mollitia saepe harum deleniti, ipsum fuga esse neque. Veniam incidunt doloremque inventore eos dolor temporibus officiis veritatis.</p>
        </ThemeProvider>
    );
}
export default Theme;
