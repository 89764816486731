import React from "react";
import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import '../src/assets/css/admin.css';
import Backend from "./components/backend ";
import Cards from "./components/cards";
import Design from "./components/desgin";
import Layout from "./components/layout/layout";
import Layout2 from "./components/layout/layout2";
import Layout3 from "./components/layout/layout3";
import Layout4 from "./components/layout/layout4";
import Registratioin from "./components/registration";
import Fsubcategory from "./components/category/fsubcategory";
import Dsubcategory from "./components/category/dsubcategory";
import Bsubcategory from "./components/category/bsubcategory";
import Login from "./components/login";
import Adminlogin from "./components/adminlogin";
import Html from "./components/frontEndPages/singlepage.js/html";
import Css from "./components/frontEndPages/singlepage.js/css";
import JavaScript from "./components/frontEndPages/singlepage.js/javascript";
import Jquery from "./components/frontEndPages/singlepage.js/jquery";
import Php from "./components/backEndPages/singlepages/php";
import Blender from "./components/uiuxPages/Ui&UxsinglePages/blender";
import Bootstrap from "./components/frontEndPages/singlepage.js/bootstrap";
import Reactjs from "./components/frontEndPages/singlepage.js/react";
import Next from "./components/frontEndPages/singlepage.js/next";
import Python from "./components/backEndPages/singlepages/python";
import Nodejs from "./components/backEndPages/singlepages/node";
import Java from "./components/backEndPages/singlepages/java";
import Ccpluse from "./components/backEndPages/singlepages/c&c++";
import Dart from "./components/backEndPages/singlepages/dart";
import Kotlin from "./components/backEndPages/singlepages/kotlin";
import Ruby from "./components/backEndPages/singlepages/ruby";
import Adobe from "./components/uiuxPages/Ui&UxsinglePages/Adobe";
import Swift from "./components/uiuxPages/Ui&UxsinglePages/swift";
import Tailwind from "./components/uiuxPages/Ui&UxsinglePages/tailwind";
import Material from "./components/uiuxPages/Ui&UxsinglePages/material";
import Chakra from "./components/uiuxPages/Ui&UxsinglePages/chakra";
import Bootstraps from "./components/uiuxPages/Ui&UxsinglePages/bootstrap";
import Layout5 from "./components/layout/layout5";
import Pricing from "./components/pricing";
import LayoutDashboard from "./Dashboard_Components/DashboardLayouts/LayoutDashboard";
import SiteConfig from "./Dashboard_Components/pages/homepage/SiteConfig";
import Homeseo from "./Dashboard_Components/pages/homepage/HomeSeo";
import Slider from "./Dashboard_Components/pages/homepage/Slider";
import Privacypolice from "./Dashboard_Components/pages/homepage/Privacypolice";
import TermsCondition from "./Dashboard_Components/pages/homepage/TermsCondition";
import About from "./Dashboard_Components/pages/About/About";
import CourseCategory from "./Dashboard_Components/pages/General-setting/Courses/CourseCategory";
import ViewCoursePost from "./Dashboard_Components/pages/General-setting/Courses/ViewCoursePost";
import Staff_record from "./Dashboard_Components/pages/General-setting/Records/staff_record";
import Interns_record from "./Dashboard_Components/pages/General-setting/Records/interns_record";
import Contact from "./Dashboard_Components/pages/Contact";
import Users from "./Dashboard_Components/pages/Users";
import Dashboard from "./Dashboard_Components/pages/Dashboard";
import Billing from "./components/billing";
import ScrollToTop from "./components/ScroolTop";
import FrontendCourse from "./components/frontEndPages/singlepage.js/frontendCourse";
import UiuxCourse from "./components/uiuxPages/Ui&UxsinglePages/uiuxCourse";
import Backendcourse from "./components/backEndPages/singlepages/backendcourse";
import SearchBar from "./components/searchbox";
import Nopage from "./components/nopage";
import Usersidebar from "./Dashboard_Components/Sidebar/Usersidebar";
import Selectedcourse from "./Dashboard_Components/internDashboard/selectedcourse";
import Upcominglecture from "./Dashboard_Components/internDashboard/upcominglecture";
import Profile from "./Dashboard_Components/internDashboard/profile";
import Exercise from "./Dashboard_Components/internDashboard/exercise";
import Popup from "./components/popup";
import Features from "./components/features";
import Dashboardintern from "./Dashboard_Components/internDashboard/Dashboardi";
import Registered_interns from "./Dashboard_Components/pages/General-setting/Records/registered_interns";
import Subscription_plans from "./Dashboard_Components/pages/General-setting/Subscription/subscription_plans";
import Viewsubscription from "./Dashboard_Components/pages/General-setting/Subscription/viewsubscription";
import Contactus from "./components/Contact";
import TermsConditions from './components/terms&condition';
import PrivacyPolicy from "./components/PrivacyPolicy";
import Whychooseus from "./components/Whychooseus";
import Joinnow from "./components/joinnow";
import Theme from "./components/theme";
function App() {
  return (
    <>
      <Router>
      <ScrollToTop excludePaths={['/ourfrontendcourses', '/ourbackendcourses', '/ourdesigncourses', '/searchbox']} />
        <Routes>
          {/* All Main Pages  Connection   */}
          <Route path="/" element={<Layout />}>
            <Route path="/ourfrontendcourses" element={<Cards />} />
            <Route path="/ourbackendcourses" element={<Backend />} />
            <Route path="/ourdesigncourses" element={<Design />} />
            <Route path="/searchbox" element={<SearchBar />} />
          </Route>
          <Route></Route>
          {/* login/registration */}
          <Route path='/' element={<Layout5 />}>
            <Route path='/billing/:id' element={<Billing />} />
            <Route path='/billing' element={<Billing />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registratioin />} />
            <Route path="/features" element={<Features />} />
            <Route path="/popup" element={<Popup />} />
            <Route path="/Contact" element={<Contactus />} />
            <Route path="/terms&condition" element={<TermsConditions />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/Whychooseus" element={<Whychooseus />} />
            <Route path="/joinnow" element={<Joinnow />} />
            <Route path="/theme" element={<Theme />}/>
            <Route path="*" element={<Nopage />} />
          </Route>

          {/* All FrontEnd Pages  Connection   */}
          <Route path="/" element={<Layout2 />}>
            <Route path="/frontendCourse/:slug" element={<FrontendCourse />} />
            <Route path="/html" element={<Html />} />
            <Route path="/css" element={<Css />} />
            <Route path="/javascript" element={<JavaScript />} />
            <Route path="/jquery" element={<Jquery />} />
            <Route path="/react" element={<Reactjs />} />
            <Route path="/nextjs" element={<Next />} />
            <Route path="/bootstrap" element={<Bootstrap />} />
          </Route>

          {/* All backEndPages  Connection   */}
          <Route path="/" element={<Layout3 />}>
            <Route path="/backendcourse/:slug" element={<Backendcourse />} />
            <Route path="/php" element={<Php />} />
            <Route path="/python" element={<Python />} />
            <Route path="/nodejs" element={<Nodejs />} />
            <Route path="/ruby" element={<Ruby />} />
            <Route path="/java" element={<Java />} />
            <Route path="/php" element={<Php />} />
            <Route path="/C&c++" element={<Ccpluse />} />
            <Route path="/dart" element={<Dart />} />
            <Route path="/kotlin" element={<Kotlin />} />
          </Route>

          {/* All UI&UX ConnectionConenction  */}
          <Route path="/" element={<Layout4 />}>
            <Route path="/uiuxCourse/:slug" element={<UiuxCourse />} />
            <Route path="/blender" element={<Blender />} />
            <Route path="/adobePhotoshop" element={<Adobe />} />
            <Route path="/swift" element={<Swift />} />
            <Route path="/bootstrap" element={<Bootstraps />} />
            <Route path="/tailwind" element={<Tailwind />} />
            <Route path="/jquery" element={<Jquery />} />
            <Route path="/material" element={<Material />} />
            <Route path="/chakra" element={<Chakra />} />
          </Route>

          {/* All Layouts  */}
          <Route path="/">
            <Route path="/fsubcategory" element={<Fsubcategory />} />
            <Route path="/dsubcategory" element={<Dsubcategory />} />
            <Route path="/bsubcategory" element={<Bsubcategory />} />
          </Route>

          {/*Admin Dashboard */}
          <Route path="/portal/admin-dashboard" element={<Adminlogin />} />
          <Route path="/admin-dashboard" element={<LayoutDashboard />} >
            <Route index element={<Dashboard />} />
            <Route path="/admin-dashboard/site-settings/site-config" element={<SiteConfig />} />
            <Route path="/admin-dashboard/site-settings/home-page" element={<Homeseo />} />
            <Route path="/admin-dashboard/site-settings/slider" element={<Slider />} />
            <Route path="/admin-dashboard/site-settings/privacy-policy" element={<Privacypolice />} />
            <Route path="/admin-dashboard/site-settings/terms-condition" element={<TermsCondition />} />
            <Route path="/admin-dashboard/site-settings/about-page" element={<About />} />
            <Route path="/admin-dashboard/general-settings/CourseCategory" element={<CourseCategory />} />
            <Route path="/admin-dashboard/general-settings/ViewCoursePost" element={<ViewCoursePost />} />
            <Route path="/admin-dashboard/general-settings/Records/interns_record" element={<Interns_record />} />
            <Route path="/admin-dashboard/general-settings/Records/registered_interns" element={<Registered_interns />} />
            <Route path="/admin-dashboard/general-settings/Records/staff_record" element={<Staff_record />} />
            <Route path="/admin-dashboard/general-settings/Subscription/subscription_plans" element={<Subscription_plans />} />
            <Route path="/admin-dashboard/general-settings/viewsubscription" element={<Viewsubscription />} />
            <Route path="/admin-dashboard/contact" element={<Contact />} />
            <Route path="/admin-dashboard/users" element={<Users />} />
            <Route path="*" element={<> *not found*</>} />
          </Route>

          {/* User Dashboard */}
          <Route path="/dashboard" element={<Usersidebar />}>
            <Route path="/dashboard/dashboardi" element={<Dashboardintern />} />
            <Route path="/dashboard/selectedcourse" element={<Selectedcourse />} />
            <Route path="/dashboard/upcominglecture" element={<Upcominglecture />} />
            <Route path="/dashboard/profile" element={<Profile />} />
            <Route path="/dashboard/exercise" element={<Exercise />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;