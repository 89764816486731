import React from "react";
import { Link, useNavigate } from "react-router-dom";
import hero from "../../src/images/hero-img.png"
import Whychooseus from "./Whychooseus";
import Joinnow from "./joinnow";
import Sliderslide from "./slider";
import RespionsiveCarousel from "./respionsiveCarousel";
function Home() {
  const navigate = useNavigate();
  const frontend = () => {
    navigate('/ourfrontendcourses')
  }
  const backend = () => {
    navigate("/ourbackendcourses")
  }
  const UI = () => {
    navigate("/ourdesigncourses")
  }
  return (
    <>
      <div className="container mt-3">
        <div className="Banner">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12">
              <div className="heading">
                <h3 className="maniheadingpge">
                  Best IT Company in <br /> Indore Madhya Pradesh <br /> India
                </h3>
                <p>
                  <strong>
                    CODE YOUR WAY To A MORE FULFILLING & HIGHER PAYING CAREER IN
                    TECH.
                  </strong>
                </p>
                <Link to="/registration">
                  <button className="startbtn">
                    <b>START FOR FREE </b>
                  </button>
                </Link>
              </div>
            </div>

            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="position-absolute">
                <img
                  src={hero}
                  alt="main img "
                  title="Sion Datamatics India Pvt ltd."
                  className="main-img img-fluid animated"
                />
              </div>
            </div>
          </div>
          {/* <RespionsiveCarousel /> */}
          <Sliderslide />
          <Whychooseus />
          <Joinnow />
          <div className="container">
            <h2 className="d-flex justify-content-center py-4 mt-4">Sharpen your coding skills</h2>
            <div className="shrpcode">
              <p className="d-flex justify-content-center py-2 fw-bold">Stay ahead of the curve by joining our dynamic coding internship program.</p>
              <p className='sharpcode'> Whether you're looking to learn a new skill, practice existing ones, or prepare for your next big interview, our program has you covered. Gain invaluable experience through hands-on projects, guided by industry experts.</p>
            </div>
          </div>
          <div className="skills">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3">
                <Link to="/">
                  <button className="btnskills firstbtn">
                    LEARN THE SKILLS TO BECOME A
                  </button>
                </Link>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3" onMouseOver={frontend}>
                <Link to="/ourfrontendcourses">
                  <button className="btnskills rounded-5">
                    <b>Frontend Developer</b>
                  </button>
                </Link>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3" onMouseOver={backend}>
                <Link to="/ourbackendcourses">
                  <button className="btnskills rounded-5">
                    <b>Backend Developer </b>
                  </button>
                </Link>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3" onMouseOver={UI}>
                <Link to="/ourdesigncourses">
                  <button className="btnskills rounded-5">
                    <b>UI/UX Designer</b>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
