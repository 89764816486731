import React, { useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import InternRegistration from "./internRegistration";
import Payments from "./payments";
import axios from "axios";

function Billing() {
    const navigate = useNavigate();
    const role = localStorage.getItem("user_role");
    const { id } = useParams();
    const location = useLocation();
    const courseData = location.state?.courseData;
    const [subscription, setSubcrption] = useState([])
    console.warn(courseData, 'courseDatabilling');
    console.warn('subscription', subscription);
    const currentDate = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString(undefined, options);

    useEffect(() => {
        if (role !== "user") {
            navigate('/popup');
        }
    }, [role, navigate]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://sdmipl.com/portal/api/internship/subscription-view')
                const pricing = response.data.records;
                if (response.status === 200) {
                    const data = pricing.find((item) => item.id == id && item)
                    console.log("filter data fetch ", data)
                    setSubcrption(data)

                } else {
                    console.log("Api not responding")
                }
            }
            catch (err) {
                console.log('Something fetching Error', err);
            }
        }

        fetchData();
    }, [id]);

    const handleProceed = () => {
        navigate("/billing", { state: { courseData: courseData } });
     
    };

    if (!courseData && !subscription) {
        return (
            <div className="container billing">
                <h1>Error: Billing Data Not Available</h1>
                <p>Sorry, the billing data is not available. Please go back and try again.</p>
            </div>
        );
    }

    return (
        <>
            <div className='wave-main'>
                <svg xmlns="http://www.w3.org/2000/svg" className='animationwave' viewBox="0 0 1440 320">
                    <path fill="#0099ff" fillOpacity="1" d="M0,256L24,250.7C48,245,96,235,144,213.3C192,192,240,160,288,133.3C336,107,384,85,432,112C480,139,528,213,576,234.7C624,256,672,224,720,192C768,160,816,128,864,138.7C912,149,960,203,1008,213.3C1056,224,1104,192,1152,170.7C1200,149,1248,139,1296,138.7C1344,139,1392,149,1416,154.7L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path>
                </svg>
                <div className="wave-display">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" className='brlink'><b>Home</b></Link></li>
                        <li className="breadcrumb-item" aria-current="page"><b>Billing Details</b></li>
                    </ol>
                </div>
            </div>
            <div className="container wavi billing">
                <h3 className='text-center'>
                    <strong>Billing Details</strong>
                </h3>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><span className="bhding">CART SUMMARY</span></Accordion.Header>
                        <Accordion.Body>
                            <div className="row align-items-center">
                                <div className="bsummary col-lg-8 col-md-6">
                                    <div className='row'>
                                        <div className="coursepic d-flex justify-content-center align-items-center col-lg-4 col-md-4">
                                            <img className="imgcrse" src={courseData?.image || subscription?.image || 'no Image found'} alt={courseData?.title || subscription?.title || 'No Title'} />
                                        </div>
                                        <div className="card col-lg-8 col-md-8">
                                            <div className="row aboutcrse py-4">
                                                <div className="bilheading">{courseData?.category || subscription?.title || 'No Category'}</div>
                                                <div className="bildetl">
                                                    <div className="col-lg-6">
                                                        <p><b>Course Title:</b></p>
                                                        <p><b>Join Date:</b></p>
                                                        <p><b>Training Mode:</b></p>
                                                        <p><b>Course Duration:</b></p>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <p>{courseData?.title || subscription?.title || '--------'}</p>
                                                        <p>{formattedDate}</p>
                                                        <p>{courseData?.internship_mode || '-------'}</p>
                                                        <p>{courseData?.duration || subscription?.duration || '--------'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bsummary col-lg-4 col-md-6">
                                    <div className=" bildetl">
                                        <div className="col-lg-6">
                                            <p><b>Total Price:</b></p>
                                            <p><b>GST(18%):</b></p>
                                            <p><b>Grand Total:</b></p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p><span className="fs-5">₹</span> {courseData?.price || subscription?.price || '0'}</p>
                                            <p><span className="fs-5">₹</span>{courseData?.price * 18 / 100}</p>
                                            <p><span className="fs-5">₹</span> {courseData?.price || subscription?.price || '0'}</p>
                                        </div>
                                        <button type="submit" onClick={handleProceed} className="registerbtn col-lg-12 justify-content-center mt-3">
                                            Proceed
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className="bheader"><span className="bhding">LEARNER DETAILS</span></Accordion.Header>
                        <Accordion.Body>
                            <InternRegistration />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header ><span className="bhding">SECURE PAYMENT</span></Accordion.Header>
                        <Accordion.Body>
                            <Payments />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
}

export default Billing;
