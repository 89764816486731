import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaShare } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { FcSearch } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

function Searchbox() {
    const [lgShow, setLgShow] = useState(false);
    const [coursedata, setCourseData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://sdmipl.com/portal/api/internship/coursepost-view");
                setCourseData(response.data.records);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);
    const handleCourseClick = (courseId, category) => {
        switch (category) {
            case "Frontend Course":
            case "Frontend":
            case "1":
                navigate(`/frontendCourse/${courseId}`);
                break;
            case "Backend Course":
            case "Backend":
            case "2":
                navigate(`/backendcourse/${courseId}`);
                break;
            case "UI/UX Course":
            case "3":
            case "design":
                navigate(`/uiuxCourse/${courseId}`);
                break;
            default:
                break;
        }
    };

    const handleInputChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        const filtered = coursedata.filter((item) =>
            item.title && item.title.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredItems(filtered);
    };

    return (
        <>
            <div className="iconsearch">
                <span className="sicon" onClick={() => setLgShow(true)}><FcSearch />
                </span>
            </div>

            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>

                    <div className="d-flex justify-content-center w-100 ">

                        <input
                            className="srch"
                            type="search"
                            aria-label="Search"
                            placeholder="Search Here..."
                            value={searchQuery}
                            onChange={handleInputChange}
                        />

                    </div>
                </Modal.Header>
                <Modal.Body className="">
                    <>
                        <div className="modalsrch">

                            <div className="item-list">
                                {searchQuery === "" ? (
                                    coursedata.map((item, index) => (
                                        <p key={index} onClick={() => handleCourseClick(item.slug, item.category)} > <span className="px-2"><FaShare />
                                        </span>
                                            {item.title} - {item.price}
                                            <hr />
                                        </p>
                                    ))
                                ) : filteredItems.length > 0 ? (
                                    filteredItems.map((item, index) => (
                                        <p key={index} > <span className="px-2"><FaShare /></span>
                                            {item.title} - Rs{item.price}
                                            <hr />
                                        </p>
                                    ))
                                ) : (
                                    <li>No matching items found</li>
                                )}
                            </div>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Searchbox;
