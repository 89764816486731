import React from 'react'
import { Link } from 'react-router-dom';
import { FaAnglesRight } from "react-icons/fa6";
function PrivacyPolicy() {
    return (
        <>
            <div className='wave-main'>
                <svg xmlns="http://www.w3.org/2000/svg" className='animationwave' viewBox="0 0 1440 320" >
                    <path fill="#0099ff" fill-opacity="1" d="M0,256L24,250.7C48,245,96,235,144,213.3C192,192,240,160,288,133.3C336,107,384,85,432,112C480,139,528,213,576,234.7C624,256,672,224,720,192C768,160,816,128,864,138.7C912,149,960,203,1008,213.3C1056,224,1104,192,1152,170.7C1200,149,1248,139,1296,138.7C1344,139,1392,149,1416,154.7L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path> </svg>
            </div>
            <div className="wave-display">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item "><Link to="/" className='brlink'><b>Home</b></Link></li>
                    <li className="breadcrumb-item" aria-current="page"><b>Privacy Policy</b></li>
                </ol>
            </div>
            <div className="container wavi tcdton">
                <h3 className='text-center mb-4'>
                    <strong >Privacy Policy</strong>
                </h3>
                <li className='position-relative tcdton-li-item'> <span className='terms'><FaAnglesRight /></span>
                    <p className='ms-4 tshead '>Privacy Policy :</p> </li>
                <p className='ms-4 py-2'>At Sion Datamatics (India) Private Limited, we are committed to protecting and respecting your privacy. This Privacy Policy sets out the basis on which any personal data we collect from you or that you provide to us will be processed by us. Please read the following carefully to understand our practices regarding your personal data and how we will treat it.
                </p>
                <li className='position-relative tcdton-li-item'> <span className='terms'><FaAnglesRight /></span>
                    <p className='ms-4 tshead '>We may collect and process the following data about you :
                    </p> </li>
                <p className='ms-4 py-2'>Information that you provide by filling in forms on our website or corresponding with us by phone, email, or otherwise. Details of your visits to our website and the resources that you access. Use of Information
                </p>
                <li className='position-relative tcdton-li-item'> <span className='terms'><FaAnglesRight /></span>
                    <p className='ms-4 tshead '>We use information held about you in the following ways :
                    </p> </li>
                <p className='ms-4 py-2'>To provide you with information or services that you request from us or which we feel may interest you, where you have consented to be contacted for such purposes. To carry out our obligations arising from any contracts entered into between you and us. To notify you about changes to our service. Disclosure of Your Information.<i>We may disclose your personal information to any member of our group, which means our subsidiaries, our ultimate holding company, and its subsidiaries.</i>
                </p>
                <li className='position-relative tcdton-li-item'> <span className='terms'><FaAnglesRight /></span>
                    <p className='ms-4 tshead '>Data Security :</p> </li>
                <p className='ms-4 py-2'>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way, altered, or disclosed.
                </p>
                <li className='position-relative tcdton-li-item'> <span className='terms'><FaAnglesRight /></span>
                    <p className='ms-4 tshead '>Your Rights :
                    </p> </li>
                <p className='ms-4 py-2'>You have the right to ask us not to process your personal data for marketing purposes. You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your data.
                </p>
            </div>
        </>
    )
}

export default PrivacyPolicy