import React from 'react'
import { Link } from 'react-router-dom';
import { FaAnglesRight } from "react-icons/fa6";

function TermsConditions() {
  return (
    <>
      <div className='wave-main'>
        <svg xmlns="http://www.w3.org/2000/svg" className='animationwave' viewBox="0 0 1440 320" >
          <path fill="#0099ff" fill-opacity="1" d="M0,256L24,250.7C48,245,96,235,144,213.3C192,192,240,160,288,133.3C336,107,384,85,432,112C480,139,528,213,576,234.7C624,256,672,224,720,192C768,160,816,128,864,138.7C912,149,960,203,1008,213.3C1056,224,1104,192,1152,170.7C1200,149,1248,139,1296,138.7C1344,139,1392,149,1416,154.7L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path> </svg>
      </div>
      <div className="wave-display">
        <ol className="breadcrumb">
          <li className="breadcrumb-item "><Link to="/" className='brlink'><b>Home</b></Link></li>
          <li className="breadcrumb-item" aria-current="page"><b>Terms of Service</b></li>
        </ol>
      </div>
      <div className="container wavi tcdton">
        <h3 className='text-center mb-4'>
          <strong >Term & Condition</strong>
        </h3>
        <li className='position-relative tcdton-li-item'> <span className='terms'><FaAnglesRight /></span>
          <p className='ms-4 tshead '>Acceptance of Terms :</p> </li>
        <p className='ms-4 py-2'>By using the services provided by Sion Datamatics (India) Private Limited, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please do not use our services.
        </p>
        <li className='position-relative tcdton-li-item'> <span className='terms'><FaAnglesRight /></span>
          <p className='ms-4 tshead '>Definitions :</p> </li>
        <p className='ms-4 py-2'>"We," "us," or "our" refers to Sion Datamatics (India) Private Limited. "Client" refers to the individual or entity using our services. "Services" refers to the products, solutions, or any other services provided by us. 3. Service Description.
        </p>
        <li className='position-relative tcdton-li-item'> <span className='terms'><FaAnglesRight /></span>
          <p className='ms-4 tshead '>Pricing and Payments :</p> </li>
        <p className='ms-4 py-2'>Payment terms and pricing details will be outlined in specific service agreements or invoices. The client is responsible for payment as agreed upon in these documents. Failure to make payments may result in the suspension or termination of services.
        </p>
        <li className='position-relative tcdton-li-item'> <span className='terms'><FaAnglesRight /></span>
          <p className='ms-4 tshead '>Confidentiality :</p> </li>
        <p className='ms-4 py-2'>Both parties agree to keep confidential any proprietary, sensitive, or non-public information shared during the course of our engagement.
        </p>
        <li className='position-relative tcdton-li-item'> <span className='terms'><FaAnglesRight /></span>
          <p className='ms-4 tshead '>Intellectual Property :</p> </li>
        <p className='ms-4 py-2'>Unless otherwise stated in a separate agreement, any intellectual property created as a result of our services remains the property of Sion Datamatics (India) Private Limited. The client is granted a non-exclusive, non-transferable license to use this intellectual property for their business purposes.
        </p>
      </div>
    </>
  )
}

export default TermsConditions;
