import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ excludePaths = [] }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!excludePaths.includes(pathname)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    else{
     console.log("section not scroll top position")
    }
  }, [pathname, excludePaths]);

  return null;
};

export default ScrollToTop;
