import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { FaSearch } from "react-icons/fa";
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { GoTasklist } from "react-icons/go";
import { useEffect, useState } from 'react';

const Registered_interns = () => {
   
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addForm, setAddForm] = useState(false);
    const [selectid, setSelectid] = useState(false);
    const [input, setInput] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [fileInput, setFileInput] = useState(null);
    console.log("id",data)
    const [formData, setFormData] = useState({
        title: '',
        id:'',
        description: '',
        references: '',
        status: ''
    });
    console.log('formData',formData);
    const userPerPage = 5;
    const [showModal, setShowModal] = useState(false);
    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://sdmipl.com/portal/api/internship/registration-view");
                setData(response.data.records);
                console.log(response,'internrecordscome')
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [addForm]);
    const handleAssignTask = (id) => {
        setFormData({ ...formData, id });
        handleModalShow(); 
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const taskid = { ...formData, user_id: localStorage.getItem("id") };
        console.log(formData);
        try {
            const res = await axios.post('https://sdmipl.com/portal/api/internship/assignment-create', taskid);
            console.log("form",res);
            Swal.fire({
                icon: "success",
                title: "Successfully",
                text: 'Task Added Successfully!!',
            });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Try Again....",
                text: 'Server error, not responding',
            });
            console.error('Error:', error);
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }
    const handleRichTextChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }
    const handleUpdate = (id) => {
        setAddForm(!addForm);
    };

    const handleDelete = async (id) => {
        try {
            await axios.post(`https://sdmipl.com/portal/api/internship/coursepost-delete/${id}`);
            const newData = data.filter(item => item.id !== id);
            setData(newData);

            Swal.fire({
                icon: 'success',
                title: "Successfully Deleted",
                showConfirmButton: false,
                timer: 2000
            });

        } catch (error) {
            console.error('There was a problem with your delete operation:', error);
        }
    };

    const filteredData = data.filter(item =>
        item.title && item.title.toLowerCase().includes(input.toLowerCase())
    );

    const pageCount = Math.ceil(filteredData.length / userPerPage);
    const offset = pageNumber * userPerPage;

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
    };

    const handleFileChange = (event) => {
        setFileInput(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        try {
            const formData = new FormData();
            formData.append('file', fileInput);

            // Make a POST request to upload the file
            const response = await axios.post("https://sdmipl.com/portal/api/upload-file", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // Handle the response
            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    if (loading) {
        return (
            <div className='container'>
                <div className='row p-3 py-10'>
                    <LinearProgress />
                </div>
            </div>
        );
    }

    return (
        <div className="container upshowing">
            <div className="row">
             
                <div className="row slider_page">

                    <div className='card card_design p-3 mt-3'>
                        <div className="col-lg-12">
                            <nav aria-label=" breadcrumb ">
                                <ol className="breadcrumb  p-3">
                                    <li className="breadcrumb-item "><Link to="/general-setting" className='brlink'>Home</Link></li>
                                    <li className="breadcrumb-item" aria-current="page">Interns Record</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-end">
                        <div className='row align-items-center '>
                            <div className=' upbtn d-flex justify-content-end'>
                                <button onClick={handleUpdate}>Add</button>
                            </div>
                        </div>
                        <div className="row g-3 p-3">
                            <div className=" col-lg-10 p-3 m-auto">
                                <label htmlFor="search" className="visually-hidden">search</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="search" placeholder="Search here" onChange={(e) => setInput(e.target.value)} />
                                    <FaSearch id='srh-icon' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="shadow-sm p-2 bg-light  table-responsive">
                        <Table className="table table-striped table-hover">
                            <Thead>
                                <Tr>
                                    <Th scope="col">#</Th>
                                    <Th scope="col">Name</Th>
                                    <Th scope="col">Email</Th>
                                    <Th scope="col">Contact Number</Th>
                                    <Th scope="col">Course</Th>
                                    <Th scope="col">Education</Th>
                                    <Th scope='col'>Mode</Th>
                                    <Th scope="col" >Address</Th>
                                    {/* <Th scope='col'>Image</Th> */}
                                    <Th scope="col">Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {data ? (
                                    data.slice(offset, offset + userPerPage).map((item, index) => (
                                        <Tr key={index}>
                                            <Td >{index + 1}</Td>
                                            <Td>{item.name}</Td>
                                            <Td>{item.email}</Td>
                                            <Td>{item.number}</Td>
                                            <Td>{item.intrestedcourse}</Td>
                                            <Td>{item.education}</Td>
                                            <Td>{item.mode}</Td>
                                            <Td>{item.address}</Td>
                                            {/* <Td><img src={item.image} /></Td> */}

                                            <Td className='action'>
                                 
                                                <button className='btn editicn' onClick={()=>handleAssignTask(item.id)}><GoTasklist /></button>
                                                <button className='btn dlticn' onClick={() => handleDelete(item.id)}><MdDelete /></button>
                                            </Td>
                                         
                                        </Tr>
                                    ))
                                ) : (
                                    <tr>
                                        <Td colSpan="4">No data Found...</Td>
                                    </tr>
                                )}
                            </Tbody>
                        </Table>
                    </div>
                    <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                        <ReactPaginate
                            nextLabel={<>Next </>}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            disabledClassName={'pagination__link--disabled'}
                            previousLabel="Previous"
                            containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                            breakClassName='page-item'
                            breakLinkClassName='page-link'
                            previousClassName='page-item'
                            previousLinkClassName='page-link'
                            nextClassName='page-item'
                            nextLinkClassName='page-link'
                            activeClassName='active  pb-3'
                            pageClassName='page-item ml-1 mr-1'
                            pageLinkClassName='page-link'
                        />
                    </div>
                    {/* modal */}
                    {/* Modal Component */}
                    <Modal className='assign' show={showModal} onHide={handleModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Assignment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* Your form fields for task */}
                            <div className="form">
                                <form onSubmit={handleSubmit} encType='multipart/form-data'>
                                    <div className="col-lg-12">
                                        <label htmlFor="title" className="form-label"> Title</label>
                                        <input
                                            type='text'
                                            name="title"
                                            value={formData.title}
                                            placeholder={"Task Title"}
                                            className='form-control'
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="description" className="form-label"> Description </label>
                                        <textarea
                                            name="description"
                                            value={formData.description}
                                            placeholder={"Task Description"}
                                            className='form-control'
                                            rows={4}
                                    onChange={handleRichTextChange}
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="references" className="form-label"> Reference </label>
                                        <textarea
                                            name="references"
                                            value={formData.references}
                                            placeholder={"Reference"}
                                            className='form-control'
                                            rows={4}
                                    onChange={handleRichTextChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 ">
                                        <div className="form-group mb-3">
                                            <label className='form-label' htmlFor='status'> Work Status</label>
                                            <select
                                                name="status"
                                                value={formData.status}
                                                className="form-control form-select"
                                                onChange={handleChange}
                                            >
                                                <option value='Pending'>Pending</option>
                                                <option value='Under-Work'>Under-Work</option>
                                                <option value='Complete'>Complete</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end  my-4'>
                                    <button className='bts' type="submit">Submit</button>

                                    </div>
                                </form>
                            </div>
                        </Modal.Body>
                    </Modal >
                </div>

            </div>

        </div>
    )
}

export default Registered_interns;