import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { IoPersonOutline } from "react-icons/io5";
import { LuPhoneCall } from "react-icons/lu";
import { IoHome } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { Link } from "react-router-dom";
import html from "../../images/html.jpg";

const Profile = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const userId = localStorage.getItem("id");

  useEffect(() => {
    const fetchLoggedInUserEmail = () => {
      const loggedInUserEmail = localStorage.getItem("userEmail");
      setFormData(prevState => ({ ...prevState, email: loggedInUserEmail }));
    };
    fetchLoggedInUserEmail();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://sdmipl.com/portal/api/login-single_record/${userId}`);
        console.log(response.data.records)
        setFormData(response.data.records);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [userId]);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  return (
    <>

      <div className="container">
        <div className="main-body">
        <div className="card card_design p-3 mt-3">
          <div className="col-lg-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb p-3">
                <li className="breadcrumb-item"><Link to="/dashboard" className='brlink'>Home</Link></li>
                <li className="breadcrumb-item" aria-current="page">Profile</li>
              </ol>
            </nav>
          </div>
        </div>
          <div className="row">
          <h3 className="heading">User Profile</h3>
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" className=" rounded-circle" width="150" />
                    <div className="mt-3">
                      <h4>{formData.name}</h4>
                      <p className="text-secondary mb-1">Full Stack Developer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0"> <IoPersonOutline className="mx-1" /> Full Name</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                      {formData.name}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0"><TfiEmail className="mx-1" /> Email</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                      {formData.email}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0"><LuPhoneCall className="mx-1" />Phone</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                      {formData.mobile || formData.number || '+91 xxxxxxxxxx'}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0"><IoHome className="mx-1" />Address</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                      Indore,India
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div className="d-flex justify-content-start px-3">
                      <Button variant="primary" className="d-flex w-auto" onClick={handleModalShow}>
                        Update Profile
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal Component */}
            <Modal show={showModal} onHide={handleModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Update Profile</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Your form fields for updating profile */}
                <div className="py-3">
                  <form>
                    <label>Enter Your name:</label>
                    <input type="text" name="name" placeholder="Enter your Name" className="form-control" />
                    <label>Enter your Mail id:</label>
                    <input type="text" name="email" placeholder="Enter your Email" className="form-control" />
                    <label>Enter Your Mobile No.</label>
                    <input type="number" name="number" placeholder="Enter your Mobile" className="form-control" />
                    <label>Address:</label>
                    <textarea className="form-control" placeholder="Update Address"></textarea>
                      <label className="form-control mt-3">Update Profile</label>
                    <input type="file" />
                  </form>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                  Close
                </Button>
                <Button variant="primary" className="ms-2">
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
