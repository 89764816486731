import React, { useEffect, useState } from 'react';
import { MdFileUpload } from "react-icons/md";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaBackward } from 'react-icons/fa';

const Siteconfigform = ({ handleUpdate }) => {

    const [formData, setFormData] = useState({
        title: '',
        contact_number: '',
        alternate_contact_number: '',
        email: '',
        alternate_email: '',
        google_map: '',
        facebook: '',
        instagram: '',
        youtube: '',
        twitter: '',
        linkedin: '',
        whatsapp: '',
        logo: null,
        fav_icon: null,
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
        canonical_links: "",
    });
    // const navigate = useNavigate();
    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await axios.get('http://127.0.0.1:8000/api/get-site-config/')
                console.log(response)
                setFormData(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetch()
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    // const handleRichTextChange = (name, value) => {
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });
    // };
    const handlelogoChange = (e) => {
        const imageFile = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            logo: imageFile,
        }));
    };
    const handlefaviconChange = (e) => {
        const imageFile = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            fav_icon: imageFile,
        }));
    };
    const handelsubmit = async (e) => {
        e.preventDefault();
        console.log("formdata", formData)

        try {


            // const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
            // const csrfToken = csrfTokenMeta ? csrfTokenMeta.content : null;
            // axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

            const formDataToSend = new FormData();
            formDataToSend.append('title', formData.title);
            formDataToSend.append('contact_number', formData.contact_number);
            formDataToSend.append('alternate_contact_number', formData.alternate_contact_number);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('alternate_email', formData.alternate_email);
            formDataToSend.append('google_map', formData.google_map);
            formDataToSend.append('facebook', formData.facebook);
            formDataToSend.append('whatsapp', formData.whatsapp);
            formDataToSend.append('instagram', formData.instagram);
            formDataToSend.append('linkedin', formData.linkedin);
            formDataToSend.append('twitter', formData.twitter);
            formDataToSend.append('youtube', formData.youtube);
            formDataToSend.append('seo_title', formData.seo_title);
            formDataToSend.append('seo_keywords', formData.seo_keywords);
            formDataToSend.append('seo_description', formData.seo_description);
            formDataToSend.append('canonical_links', formData.canonical_links);

            // if (formData.logo) {
            //     formDataToSend.append('logo', formData.logo);
            // }
            if (formData.logo && typeof formData.logo !== 'string') {
                formDataToSend.append('logo', formData.logo);
            }

            if (formData.fav_icon && typeof formData.fav_icon !== 'string') {
                formDataToSend.append('fav_icon', formData.fav_icon);

            }


            const response = await axios.post('http://127.0.0.1:8000/api/update-site-config/', formDataToSend)
            console.log("res", response)
            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    text: "Data updated successfully!",
                });
                handleUpdate()
            } else {
                Swal.fire({
                    icon: "error",
                    title: "There was an error in updating the data. Please try again.",
                });
                handleUpdate()
            }

        } catch (error) {
            console.log("axios", error)
        }

    }
    return (
        <div className="container ">
            <div className='upbtn'>

                <button onClick={handleUpdate}><FaBackward /> Back </button>
            </div>
            <form className='shadow p-5' onSubmit={handelsubmit} encType='multipart/form-data'>

                <div className="row m-3">

                    <div className="col-lg-6">
                        <label htmlFor="email" className="form-label">E-mail</label>
                        <input
                            type="email"
                            name='email'
                            onChange={handleInputChange}
                            value={formData.email}
                            className="form-control"
                            placeholder="Enter email"
                        />
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="alternate_email" className="form-label">Alternate E-mail</label>
                        <input
                            type="email"
                            name='alternate_email'
                            onChange={handleInputChange}
                            value={formData.alternate_email}
                            className="form-control"
                            placeholder="Enter alternate email"
                        />
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="contact_number" className="form-label">Mobile </label>
                        <input
                            type="number"
                            name='contact_number'

                            onChange={handleInputChange}
                            value={formData.contact_number}

                            className="form-control"
                            placeholder="Enter mobile number"
                        />
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="alternate_contact_number" className="form-label">Alternate Mobile </label>
                        <input
                            type="number"

                            name='alternate_contact_number'
                            onChange={handleInputChange}
                            value={formData.alternate_contact_number}
                            className="form-control"
                            placeholder="Enter alternate mobile number"
                        />
                    </div>

                    <div className='row mt-4 mb-4 '>
                        <h6>social media links</h6>
                        <div className="col-lg-4">
                            <label htmlFor="google_map" className="form-label">Google Map</label>
                            <input
                                type="url"
                                name='google_map'
                                onChange={handleInputChange}
                                value={formData.google_map}
                                className="form-control"
                                placeholder="Enter Google Map link"
                            />
                        </div>
                        <div className="col-lg-4">
                            <label htmlFor="facebook" className="form-label">Facebook</label>
                            <input
                                type="url"
                                name='facebook'
                                onChange={handleInputChange}
                                value={formData.facebook}
                                className="form-control"
                                placeholder="Enter Facebook link"
                            />
                        </div>
                        <div className="col-lg-4">
                            <label htmlFor="instagram" className="form-label">Instagram</label>
                            <input
                                type="url"
                                name='instagram'
                                onChange={handleInputChange}
                                value={formData.instagram}
                                className="form-control"
                                placeholder="Enter Instagram link"
                            />
                        </div>
                        <div className="col-lg-4">
                            <label htmlFor="youtube" className="form-label">Youtube</label>
                            <input
                                type="url"
                                name='youtube'
                                onChange={handleInputChange}
                                value={formData.youtube}
                                className="form-control"
                                placeholder="Enter Youtube link"
                            />
                        </div>
                        <div className="col-lg-4">
                            <label htmlFor="twitter" className="form-label">Twitter</label>
                            <input
                                type="url"
                                name='twitter'
                                onChange={handleInputChange}
                                value={formData.twitter}
                                className="form-control"
                                placeholder="Enter Twitter link"
                            />
                        </div>
                        <div className="col-lg-4">
                            <label htmlFor="linkedin" className="form-label">LinkedIn</label>
                            <input
                                type="url"
                                name='linkedin'
                                onChange={handleInputChange}
                                value={formData.linkedin}
                                className="form-control"
                                placeholder="Enter LinkedIn link"
                            />
                        </div>
                        <div className="col-lg-4">
                            <label htmlFor="whatsapp" className="form-label">WhatsApp</label>
                            <input
                                type="url"
                                name='whatsapp'
                                onChange={handleInputChange}
                                value={formData.whatsapp}
                                className="form-control"
                                placeholder="Enter LinkedIn link"
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <label htmlFor="seo_title" className="form-label">Seo-Title</label>
                        <input
                            type="text"
                            name='seo_title'
                            onChange={handleInputChange}
                            value={formData.seo_title}
                            className="form-control"
                            placeholder="Enter Seo link"
                        />
                    </div>
                    <div className="col-lg-4">
                        <label htmlFor="seo_keywords" className="form-label">Seo-Keywords</label>
                        <input
                            type="text"
                            name='seo_keywords'
                            onChange={handleInputChange}
                            value={formData.seo_keywords}
                            className="form-control"
                            placeholder="Enter Seo keywords"
                        />
                    </div>

                    <div className="col-lg-4">
                        <label htmlFor="canonical_links" className="form-label">Seo canonial-links</label>
                        <input
                            type="text"
                            name='canonical_links'
                            onChange={handleInputChange}
                            value={formData.canonical_links}
                            className="form-control"
                            placeholder="Enter Seo Cononial-links"
                        />
                    </div>

                    <div className="col-lg-12">
                        <label htmlFor="seo_description" className="form-label">Seo-Description</label>
                        <textarea
                            type="text"
                            rows={3}
                            name='seo_description'
                            onChange={handleInputChange}
                            value={formData.seo_description}
                            className="form-control"
                            placeholder="Enter Seo Description"
                        />
                    </div>
                </div>
                {/* image section logo + Favicon */}
                < div className="row className='d-flex justify-content-around shadow'" >
                    <div className="formgroup col-lg-4 profile text-center ">
                        <div className="card">
                            <label htmlFor="logoupdate" className="form-label lable"><MdFileUpload className='image-upload' /> Update Logo </label>
                            <input
                                onChange={handlelogoChange}
                                name='logo'
                                className='form-file'
                                multiple={false}
                                accept=".jpg,.jpeg,.png,.svg+xml"
                                type="file"
                            />
                            {formData.logo && (
                                <div className='img-priview'>
                                    <img src={formData.logo} alt="Preview" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="formgroup col-lg-4 profile text-center">
                        <div className="card ">
                            <label htmlFor="favicon" className="form-label lable"><MdFileUpload className='image-upload' /> Update Favicon</label>
                            <input className="form-file" name='fav_icon' onChange={handlefaviconChange}
                                type="file" />
                            {formData.fav_icon && (
                                <div className='img-priview'>
                                    <img src={formData.fav_icon} alt="Preview" />
                                </div>
                            )}
                        </div>
                    </div>
                </div >
                <div className='d-flex float-right'><button type='submit' className="btn btn-primary">Submit</button></div>
            </form >
        </div >

    );
}
export default Siteconfigform