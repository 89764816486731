import axios from 'axios';
import React, { useState } from 'react';
import { FaRegEyeSlash, FaEye } from 'react-icons/fa';
import { IoIosMail } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function Adminlogin() {
  const [viewPassword, setViewPassword] = useState(true);
  const [formData, setFormData] = useState({
    admin_id: '',
    password: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const passwordViewHandler = () => {
    setViewPassword(!viewPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://sdmipl.com/portal/api/super-admin-login', formData);

      console.log("formData" , formData);
      console.log(res);
      if (res.status===200) {
        const accessToken = res.data.token;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('id', res.data.id);

        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Admin Login Successfully!',
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        setFormData({
          admin_id: '',
          password: '',
        })

        navigate("/admin-dashboard");
      } else {
        throw new Error('Invalid login');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: error.response?.data?.message || 'Please enter a valid Admin ID & password',
      });
      console.error('Login error:', error);
    }
  };

  return (
    <>
      <div className="card adminLogin mt-5 col-lg-6">
        <div className="card-body mt-5">
          <div className="mb-5">
            <h3>Portal Login</h3>
            <p className="text-muted">Sign in to your account to continue.</p>
          </div>
          {/* Login form */}
          <form className="login-signup-form mt-5" onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="rgform">Admin ID</label>
              <div className="input-group input-group-merge">
                <div className="input-icon">
                  <IoIosMail />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Admin ID"
                  name="admin_id"
                  onChange={handleChange}
                  required
                  value={formData.admin_id}
                />
              </div>
            </div>
            {/* Password */}
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label className="rgform">Password</label>
                </div>
              </div>
              <div className="input-group input-group-merge">
                <div className="input-icon">
                  <p className="password-eye" value={formData.password} onClick={passwordViewHandler}>
                    {viewPassword ? <FaEye /> : <FaRegEyeSlash />}
                  </p>
                </div>
                <input
                  name="password"
                  type={viewPassword ? 'text' : 'password'}
                  placeholder="Enter your password"
                  className="form-control"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            {/* Submit */}
            <button type="submit" className="signbtn">
              Sign in
            </button>
          </form>
        </div>
        <div className="card-footer bg-soft text-center border-top px-md-5">
          <small>Not registered? </small>
          <Link to="/registration" className="small formlink">
            Create Now
          </Link>
        </div>
      </div>
    </>
  );
}

export default Adminlogin;
