import React from 'react'
import { Outlet } from 'react-router-dom';
import Fsubcategory from '../category/fsubcategory';

function Layout2() {
  return (
   <>
    <Fsubcategory />
     <Outlet />
   </>
  )
}

export default Layout2;