import React from "react";
import css from "../../../images/css.png";
import Collapsible from "react-collapsible";
import { Link } from "react-router-dom";
import { SlActionRedo } from "react-icons/sl";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import PriceCard from "../../priceCard";
function Css() {
  return (
    <>
      <div className="banner-sp-inerheading mt-4">
        <div id="banertext">
          <div className="bner-sp-heading">
            <h1>Cascading Style Sheets (CSS)</h1>
          </div>
          <div className="bner-sp-headingsmall">
            Increase Your Data Analytic Skills - Highly Valued And Sought After
            By Employers
          </div>

          <div className="bdge-rating">
            <div className="badge">Best-Sellers</div>
            <div className="banner-rating">
              <span className="star">Rating:</span>
            </div>
            <div className="created">
              <span className="text">Created by</span>
              <span className="text-link">Quantitative Specialists </span>
            </div>
          </div>
        </div>
        <div className="sp-video">
          <video width="320" height="180" controls>
            <source src="movie.mp4" type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="bner-sp">
            <div className="bner-heading ">
              <div className="title">
                <div className="title-text">Cascading Style Sheets (CSS)</div>
              </div>
              <div className="bdge-rating">
                <div className="badge">Best-Sellers</div>
                {/* <div className='banner-rating'>
                                <span className='star'>Rating: 4.6 out of 5</span>
                            </div> */}
              </div>
            </div>
          </div>

          <div className="contentsp" style={{}}>
            <div className="singlepge pt-5">
              <div className="heading">Cascading Style Sheets (CSS)</div>
            </div>
            <div className="features">
              <div className=" images col-lg-6 col-md-6 col-sm-12 ">
                <img src={css} alt="htmlImages" />
              </div>
              <div className=" col-lg-6 col-md-6 col-sm-12 ">
                <div className="spcontent">
                  <strong>Welcome to CSS (Cascading Style Sheets)</strong>
                  CSS is a style sheet language used for describing the
                  presentation of a document written in HTML or XML. It allows
                  web developers to control the layout, appearance, and
                  formatting of multiple web pages simultaneously. CSS separates
                  the content of a web page from its visual presentation,
                  enabling consistent styling across various pages and devices.
                  Selectors in CSS target HTML elements, and declarations define
                  how those elements should be styled, including properties such
                  as color, font size, spacing, and positioning. The "cascading"
                  aspect refers to the order of priority for conflicting styles,
                  allowing developers to create a hierarchy of styles and apply
                  them in a predictable manner. CSS plays a crucial role in
                  creating visually appealing and user-friendly websites.
                </div>
              </div>
            </div>
            {/* </div>
            </div> */}
            <div className="singlepge mb-5">
              <div className="heading">Internship Mode: Online Experience</div>
            </div>
            <div className="features-second">
              <div className="row">
                <div className="col-lg-6 col-md-5 col-sm-12 mt-2 mt-3">
                  <div className="sponline">
                    <strong>Why Choose Online Internship Mode?</strong>
                    <br />
                    <strong>Real-world Application:</strong> Bridge the gap
                    between theory and practice by working on projects that
                    mimic real-world scenarios.
                    <br />
                    <strong>Resume Boost:</strong> Enhance your resume with
                    hands-on experience and a certificate of completion from our
                    internship program.
                    <br />
                    <strong>Industry Recognition:</strong> Stand out in the
                    competitive job market with a portfolio that showcases your
                    ability to apply HTML skills in practical settings.
                  </div>
                </div>
                <div className="col-lg-6 col-md-7 col-sm-12">
                  <div className="list">
                    <ul>
                      <li>Live Projects with Industry Partners</li>
                      <li>Mentorship Program</li>
                      <li> Networking Opportunities</li>
                      <li>Portfolio Enhancement</li>
                      <li>Flexible Timing</li>
                      <li>Certificate of Completion</li>
                      <li>Feedback and Evaluation</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="course_1  ">
              <h3 className="courseHeading">This course includes:</h3>
              <li>
                <strong>Cascading:</strong> CSS follows a cascading order of
                precedence, where styles can be inherited from parent elements,
                overridden by more specific selectors, or influenced by the
                order of stylesheets
              </li>
              <li>
                <strong>Box Model:</strong> The box model is a fundamental
                concept in CSS, describing how elements are rendered as boxes
                with content, padding, border, and margin. Understanding the box
                model is crucial for designing layouts.
              </li>
              <li>
                <strong>Responsive Design:</strong> CSS allows the creation of
                responsive designs that adapt to different screen sizes and
                devices. Techniques like media queries enable developers to
                apply styles based on the characteristics of the user's device.
              </li>
              <li>
                <strong>Transitions and Animations:</strong> CSS provides
                features for creating smooth transitions and animations,
                enhancing the user experience by adding dynamic and interactive
                elements.
              </li>
              <li>
                CSS plays a crucial role in web development, helping developers
                create visually appealing and well-organized web pages. It is
                widely supported by browsers and continues to evolve with new
                features and capabilities.
              </li>
              <hr />
            </div>
            <div className="course_2">
              <h3 className="courseHeading">Who this course is for:</h3>

              <li>
                <SlActionRedo />
                Aspiring Web Developers
              </li>
              <li>
                <SlActionRedo /> Students interested in Web Coding
              </li>
              <li>
                <SlActionRedo /> Students interested in Front-End Web Page
                Design
              </li>
              <li>
                <SlActionRedo />
                Students interested in learning the essentials of Full Stack Web
                Development
              </li>
              <li>
                <SlActionRedo /> Students interested in learning HTML, CSS, &
                Bootstrap
              </li>
              <hr />
            </div>

            <div className="spcontents">
              <div className="spcollpase row">
                <div className=" col-lg-6 col-md-6 col-sm-12 mt-3">
                  {/* <div className="spcontent" id="spcontent"> */}
                  <strong>Skills you'll gain</strong>
                  <small>
                    <ul>
                      <li>
                        Earn a career certificate Add this credential to your
                        LinkedIn profile, resume, or CV Share it on social media
                        and in your performance review
                      </li>
                    </ul>
                  </small>

                  <strong>What you'll learn</strong>
                  <small>
                    <ul>
                      <li>Recognize and use common HTML5 tags.</li>
                      <li>Be aware of what an editor is and how to use one.</li>
                      <li>
                        Compose HTML5 code that can create images and links.
                      </li>
                    </ul>
                  </small>
                </div>
              </div>

              <div className="spcontent">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <ul>
                    <h4>Key Features</h4>
                    <li>
                      <span>
                        <IoIosArrowDropdownCircle />
                      </span>
                      <Collapsible trigger="Foundational Knowledge">
                        <p>
                          Understand the core concepts of HTML, including
                          elements, attributes, and document structure.
                        </p>
                      </Collapsible>
                    </li>
                    <hr />
                    <li>
                      <span>
                        <IoIosArrowDropdownCircle />
                      </span>
                      <Collapsible trigger="Hands-on Experience">
                        <p id="colpseopn">
                          Dive into practical coding exercises to reinforce
                          theoretical concepts and build confidence in your
                          coding abilities.
                        </p>
                      </Collapsible>
                    </li>
                    <hr />
                    <li>
                      <span>
                        <IoIosArrowDropdownCircle />
                      </span>
                      <Collapsible trigger="Project-Based Learning">
                        <p>
                          Apply your skills to real-world projects, gaining
                          valuable experience that can be showcased in your
                          portfolio.
                        </p>
                      </Collapsible>
                    </li>
                    <hr />
                    <li>
                      <span>
                        <IoIosArrowDropdownCircle />
                      </span>
                      <Collapsible trigger="Interactive Quizzes">
                        <p>
                          Assess your understanding with module-wise quizzes,
                          ensuring a solid grasp of each topic before moving
                          forward.
                        </p>
                      </Collapsible>
                    </li>
                    <hr />
                    <li>
                      <span>
                        <IoIosArrowDropdownCircle />
                      </span>
                      <Collapsible trigger="Community Support">
                        <p>
                          Engage with fellow learners in discussion forums,
                          fostering a collaborative learning environment. Our
                          community is here to support and inspire you
                          throughout your learning journey.
                        </p>
                      </Collapsible>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="spcontent_last_data d-flex justify-content-between align-items-center">
              <div className="first">
                <div className="price">
                  <h5>
                    Price:
                    <strong> $ 999</strong>
                  </h5>
                </div>
                <div className="duration">
                  <h5>
                    Duration:
                    <strong> 6 Month</strong>
                  </h5>
                </div>
              </div>
              {/* <div className="second">
              <div className="price">$999</div>
              <div className="duration">6 Month</div>
            </div> */}
              <Link to="/billing">
                <button className="spj">
                  <b>Join Now</b>
                </button>
              </Link>
            </div>
          </div>
          <PriceCard />
        </div>
      </div>
    </>
  );
}

export default Css;
