import React from 'react'
import Dsubcategory from '../category/dsubcategory'
import { Outlet } from 'react-router-dom'
 
function layout4() {
  return (
    <>
     <Dsubcategory />
     <Outlet />
    </>
  )
}

export default layout4;