import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"
function Bsubcategory() {
  const [coursedata, setCourseData] = useState([]);
  const [filterdata, setFilterData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://sdmipl.com/portal/api/internship/coursepost-view");
        setCourseData(response.data.records);
        console.log(response.data.records, 'headerfetching');
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const uniquedata = coursedata.filter(
      (item) =>
        item.category === "Backend Course" || item.category === "Backend" || item.category === "2"
    );
    setFilterData(uniquedata);
  }, [coursedata]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="subcateogory">
            <div>
              <Link to="/ourbackendcourses">
                <button className="Subbutton-main">
                  <b>Backend Developer</b>
                </button>
              </Link>
              <button className="clippath">clippath</button>
              {filterdata && filterdata.length > 0 ? (
                filterdata.map((course, index) => (
                  <span key={index}>
                    <Link to={`/backendcourse/${course.slug}`}>
                      <button className="subtn">{course.title}</button>
                    </Link>
                  </span>
                ))
              ) : (
                <span className="loader"></span>
              )}
              
              {/* <Link to="/php">
                <button className="subtn">Php</button>
              </Link>
              <Link to="/python">
                <button className="subtn">Python</button>
              </Link>
              <Link to="/nodejs">
                <button className="subtn">Node JS</button>
              </Link>
              <Link to="/ruby">
                <button className="subtn">Ruby </button>
              </Link>
              <Link to="/java">
                <button className="subtn">Java</button>
              </Link>
              <Link to="/C&c++">
                <button className="subtn">C&C++</button>
              </Link>
              <Link to="/dart">
                <button className="subtn">Dart</button>
              </Link>
              <Link to="/kotlin">
                <button className="subtn">Kotlin</button>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Bsubcategory;
