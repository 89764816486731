import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { LinearProgress } from '@mui/material'
import { Link } from 'react-router-dom';
import AboutUpdate from './AboutUpdate';

const About = () => {
  const [formOpen, setFormOpen] = useState(false);
  const [Data, setData] = useState('');
  const handleUpdate = () => {
    setFormOpen(!formOpen);
  };
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get('http://127.0.0.1:8000/api/get-about/')
        setData(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetch()
  }, [formOpen])
  if (!Data) {
    <div className='container'>
      <div className='row p-5'>
        <LinearProgress />
      </div>
    </div>
  }
  return (

    <section className='upshowing'>
      <div className="row">
        <div className="col-lg-12" id='nav'>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb p-3 ">
              <li className="breadcrumb-item "><Link to="/admin-dashboard" title="Home page" className='text-decoration-none '>Admin-dashbaord</Link></li>
              <li className="breadcrumb-item" aria-current="page">About Page</li>
            </ol>
          </nav>
        </div>
      </div >
      {formOpen ? (

        <AboutUpdate handleUpdate={handleUpdate} />

      ) :
        <div className='container'>

          <div className='row align-items-center '>

            <div className='col-2 upbtn d-flex justify-content-end'>
              <button onClick={handleUpdate}>update</button>
            </div>
          </div>

          <div className='row '>
            <div className='col-lg-12'>
              <div className='card card_design p-3 mt-3'>
                <div className='row align-items-center'>
                  <div className='col-lg-6'>

                   <div className='card shadow'>
                 <div className='ab-image'>
                 <img src={Data.about_image} alt={Data.title} title={Data.title} />
                 </div>

                   </div>
                  </div>
                  <div className='col-lg-6'>
                   <div className='card shadow p-2'>
                   <h5 className="card-title">{Data.title}</h5>
                    <div dangerouslySetInnerHTML={{ __html: Data.description }} />

                   </div>
                  </div>

                </div>
              </div>
              <div className='card card_design p-3 mt-3'>
                <h3>Seo Parameters</h3>
                <div className='row'>
                  <div className='first col-md-5'>
                    <h4>Seo-Title</h4>
                    <h4>Seo-Keywords</h4>
                    <h4>Seo Description</h4>
                  </div>
                  <div className='second col-md-7'>
                    <p>{Data.seo_title}</p>
                    <p>{Data.seo_keywords}</p>
                    <p>{Data.seo_description}</p>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      }
    </section>
  )
}
export default About;