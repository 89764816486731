import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import RichText from '../Subpages/RichTexteditor';
import { LinearProgress } from '@mui/material';

const AboutUpdate = ({handleUpdate}) => {
    const [Loading , setLoading] = useState(true);
    const [formData, setFormData] = useState({
        title: '',
        description: "",
        about_image:null,
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
    });
    // const navigate = useNavigate();
    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await axios.get('http://127.0.0.1:8000/api/get-about')
                setFormData(response.data)
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        }
        fetch()
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleimage = (e) => {
      const imageFile = e.target.files[0];
      setFormData((prevData) => ({
          ...prevData,
          about_image: imageFile,
      }));
  };
    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handelsubmit = async (e) => {
        e.preventDefault();
        try {
           
          const formDataToSend = new FormData();
          formDataToSend.append('title', formData.title);
          formDataToSend.append('description', formData.description);
          
          formDataToSend.append('seo_title', formData.seo_title);
          formDataToSend.append('seo_keywords', formData.seo_keywords);
          formDataToSend.append('seo_description', formData.seo_description);
          // if (formData.logo) {
          //     formDataToSend.append('logo', formData.logo);
          // }
          if (formData.about_image && typeof formData.about_image !== 'string') {
              formDataToSend.append('about_image', formData.about_image);
          }
            const response = await axios.post('http://127.0.0.1:8000/api/update-about/', formDataToSend);
            console.log("res", response)
            if (response.status === 200) {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "About has been saved",
                    showConfirmButton: false,
                    timer: 1500
                  });
                handleUpdate()
            } else {
                Swal.fire({
                    icon: "error",
                    title: "There was an error in updating the data. Please try again.",
                });
                handleUpdate()
            }
        } catch (error) {
            console.log("axios", error)
        }
    }
    if (Loading) {
        <div className='container'>
            <div className='row p-5'>
                <LinearProgress />
            </div>
        </div>
    }
    
    return (
        <div className="container ">
            
            <div className='upbtn'>
                <button onClick={handleUpdate}>back </button>
            </div>
            <form className='shadow p-2 ' onSubmit={handelsubmit} encType='multipart/form-data'>
                <div className="row m-3">
                    <div className="col-lg-6">
                        <label htmlFor="title" className="form-label">Title</label>
                        <input
                            type="text"
                            name='title'
                            onChange={handleInputChange}
                            value={formData.title}
                            className="form-control"
                            placeholder="Enter title"
                        />
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="title" className="form-label">image</label>
                        <input
                            type="file"
                            name='about_image'
                            onChange={handleimage}
                            
                            className="form-control"
                            accept='*'
                        />
                    </div>

                    <div className="col-lg-12">
                        <label htmlFor="terms" className="form-label">Descrption</label>
                        <RichText
                            name="description"
                            placeholder={"description"}
                            value={formData.description}
                            onChange={handleRichTextChange}
                        />

                    </div>

                   <div className='row mt-3 '>
                    <h6 className='py-2'>Seo-Parameters</h6>
                    <div className="col-lg-6">
                        <label htmlFor="seo_title" className="form-label">Seo-Title</label>
                        <input
                            type="text"
                            name='seo_title'
                            onChange={handleInputChange}
                            value={formData.seo_title}
                            className="form-control"
                            placeholder="Enter Seo link"
                        />
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="seo_keywords" className="form-label">Seo-Keywords</label>
                        <input
                            type="text"
                            name='seo_keywords'
                            onChange={handleInputChange}
                            value={formData.seo_keywords}
                            className="form-control"
                            placeholder="Enter Seo keywords"
                        />
                    </div>


                    <div className="col-lg-12">
                        <label htmlFor="seo_description" className="form-label">Seo-Description</label>
                        <textarea
                            type="text"
                            rows={3}
                            name='seo_description'
                            onChange={handleInputChange}
                            value={formData.seo_description}
                            className="form-control"
                            placeholder="Enter Seo Description"
                        />
                    </div>
                    </div>
                </div>
                <div className='d-flex float-right'><button type='submit' className="btn btn-primary">Submit</button></div>
            </form >
        </div >

    );
}
export default AboutUpdate;