import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { FaSearch } from "react-icons/fa";
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Contact = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [input, setInput] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 5;
    let pageCount = Math.ceil(data.length / usersPerPage);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://sdmipl.com/portal/api/internship/massage-view");
                setData(response.data.records);
                console.log(response, 'response')
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const handleDelete = async (id) => {
        try {
            const response = await axios.post(`https://sdmipl.com/portal/api/internship/massage-delete/${id}`);
            console.log(response)
            console.log("Deleted item with ID:", id);

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: 'success',
                title: "Successfully Deleted",
            });
        } catch (error) {
            console.error('There was a problem with your delete operation:', error);
        }
    };

    const filteredData = data.filter(item =>
        item.name.toLowerCase().includes(input.toLowerCase())
    );
    pageCount = Math.ceil(filteredData.length / usersPerPage);
    const offset = pageNumber * usersPerPage;
    const endIndex = Math.min(offset + usersPerPage, filteredData.length);
    const startIndex = offset;
    const displayedData = filteredData.slice(startIndex, endIndex);

    const handelPageClick = ({ selected }) => {
        setPageNumber(selected);
    };

    if (loading) {
        return (
            <div className='container'>
                <div className='row p-5'>
                    <LinearProgress />
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid upshowing">
            <div className="row">
                <div className="col-lg-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb  p-3">
                            <li className="breadcrumb-item "><Link to="/general-setting" className='text-decoration-none brlink '>Home</Link></li>
                            <li className="breadcrumb-item" aria-current="page">Contact</li>
                        </ol>
                    </nav>
                </div>

                <div className="row">
                    <div className="col-lg-12 d-flex justify-content-end">
                        <div className="row g-3 p-3">
                            <div className=" col-lg-10 p-3 m-auto">
                                <label htmlFor="search" className="visually-hidden">Search</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="search" placeholder="Search here" onChange={(e) => setInput(e.target.value)} />
                                    <FaSearch id='srh-icon' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <table className="table table-striped table-hover text-center ">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Message</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedData.length > 0 ? (
                            displayedData.map((item, index) => (
                                <tr key={item.id}>
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td>{item.massage}</td>
                                    <td className=''>
                                        <button type='button' className='btn btn-danger ms-2' onClick={() => handleDelete(item.id)}>Delete</button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7">No data Found...</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                    <ReactPaginate
                        nextLabel={<>Next </>}
                        pageCount={pageCount}
                        onPageChange={handelPageClick}
                        disabledClassName={'pagination__link--disabled'}
                        previousLabel="Previous"
                        containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                        breakClassName='page-item'
                        breakLinkClassName='page-link'
                        previousClassName='page-item'
                        previousLinkClassName='page-link'
                        nextClassName='page-item'
                        nextLinkClassName='page-link'
                        activeClassName='active pb-3'
                        pageClassName='page-item ml-1 mr-1'
                        pageLinkClassName='page-link'
                    />
                </div>
            </div>
        </div>
    );
}

export default Contact;
