import React, { useState, useEffect } from 'react'
import Siteconfigform from '../Subpages/Siteconfigform'
import axios from 'axios'
import { LinearProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import { FaPen } from 'react-icons/fa'

const SiteConfig = () => {
  const [formOpen, setFormOpen] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [siteconfig, setSiteconfig] = useState('');
  const handleUpdate = () => {
    setFormOpen(!formOpen);
  };
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get('http://127.0.0.1:8000/api/get-site-config/')
        setSiteconfig(response.data)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    fetch()
  }, [formOpen])
  if (Loading) {
    <div className='container'>
      <div className='row p-5'>
        <LinearProgress />
      </div>
    </div>
  }
  return (

    <section className='upshowing'>
      <div className='container'>

        <div className="row">
          <div className="col-lg-12" id='nav'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb p-3">
                <li className="breadcrumb-item "><Link to="/admin-dashboard" title="Home page" className='text-decoration-none '>Admin-dashboard</Link></li>
                <li className="breadcrumb-item" aria-current="page">Site-config</li>
              </ol>
            </nav>
          </div>
        </div >
        {formOpen ? (
          <Siteconfigform handleUpdate={handleUpdate} />
        ) :

          <div>
            <div className='row align-items-center justify-content-center p-3'>
              <div className='col-lg-6'>
               
                <div className='pic'>
                  <img src={siteconfig.logo} alt={siteconfig.seo_title} title={siteconfig.seo_title} />
                </div>
                <h6  className='mt-3'>Logo image</h6>
              </div>
              <div className='col-lg-6 '>
                <div className='pic'>
                  <img src={siteconfig.fav_icon} alt={siteconfig.seo_title} title={siteconfig.seo_title} />
                </div>
                <h6 className='mt-3'>Fav icon</h6>
              </div>
            </div>
              <div className='col-2 upbtn'>
                <button onClick={handleUpdate}><FaPen className='py-0.5' /> Edit</button>
              </div>

            <div className='row '>
              <div className='col-lg-12'>
                <div className='card card_design p-3 mt-3'>
                  <h3>Personal Info:</h3>
                  <div className='row'>
                    <div className='first col-md-5'>
                      <h4>Email </h4>
                      <h4>Alternate Email </h4>
                      <h4>Mobile </h4>
                      <h4>Alternate Mobile </h4>
                      <h4>Address :  </h4>
                    </div>
                    <div className='second col-md-7'>
                      <p>{siteconfig.email}</p>
                      <p> {siteconfig.alternate_email}</p>
                      <p>{siteconfig.contact_number}</p>
                      <p>{siteconfig.alternate_contact_number}</p>
                      <p>Address Indore</p>
                    </div>
                  </div>
                </div>
                <div className='card  card_design p-3 mt-3'>
                  <h3>Social links:</h3>
                  <div className='row'>
                    <div className='first col-md-5'>
                      <h4>Twitter</h4>
                      <h4>LinkedIn</h4>
                      <h4>Youtube</h4>
                      <h4>Maps</h4>
                      <h4>Whatsapp</h4>
                      <h4>Facebook</h4>
                      <h4>Instagram</h4>
                    </div>
                    <div className='second col-md-7'>
                      <p>{siteconfig.twitter}</p>
                      <p>{siteconfig.linkedin}</p>
                      <p>{siteconfig.youtube}</p>
                      <p>{siteconfig.google_map}</p>
                      <p>{siteconfig.whatsapp}</p>
                      <p>{siteconfig.facebook}</p>
                      <p>{siteconfig.instagram}</p>

                    </div>
                  </div>
                </div>

                <div className='card card_design p-3 mt-3'>
                  <h3>Seo Parameters</h3>
                  <div className='row'>
                    <div className='first col-md-5'>
                      <h4>Seo-Title</h4>
                      <h4>Seo-Keywords</h4>
                      <h4>Seo Description</h4>
                      <h4>Seo Canonical-links</h4>
                    </div>
                    <div className='second col-md-7'>
                      <p>{siteconfig.seo_title}</p>
                      <p>{siteconfig.seo_keywords}</p>
                      <p>{siteconfig.seo_description}</p>
                      <p>{siteconfig.canonical_links}</p>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        }
      </div>
    </section>
  )
}

export default SiteConfig;