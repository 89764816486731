import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

function InternRegistration() {
  const { id } = useParams();
  const location = useLocation();
  const [data, setData] = useState({});
  const [subscription, setSubcrption] = useState([])
  const courseData = location.state?.courseData;
  console.log(subscription ,'subscription details');
  const userId = localStorage.getItem("id");

     useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://sdmipl.com/portal/api/internship/subscription-view')
                const pricing = response.data.records;
                if (response.status === 200) {
                    const data = pricing.find((item) => item.id == id && item)
                    console.log("filter data fetch ", data)
                    setSubcrption(data)

                } else {
                    console.log("Api not responding")
                }
            }
            catch (err) {
                console.log('Something fetching Error', err);
            }
        }

        fetchData();
    }, [id]);
    const handelpayment = () => {
    // navigate("/payments", { state: { internData: internData } });
   
    console.log('datasentpayement', internData);
  }
  const [internData, setInternData] = useState({
    user_id: userId || null,
    name: '',
    email: '',
    number: '',
    intrestedcourse: courseData ? courseData.category || '' : '',
    selected_course: courseData ? courseData.title : '',
    mode: courseData ? courseData.internship_mode : '',
    duration: courseData ? courseData.duration || '' : '',
    price: courseData ? courseData.price || '' : '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://sdmipl.com/portal/api/login-single_record/${userId}`);
        const userData = response.data.records;
        setData(userData);
        setInternData(prevData => ({
          ...prevData,
          name: userData.name || '',
          email: userData.email || '',
          number: userData.mobile || userData.number || '',
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formdata", internData)
    try {
      const res = await axios.post(
        'https://sdmipl.com/portal/api/internship/registration-create',
        internData);
      console.log("repsonse submit", res)
      Swal.fire({
        icon: 'success',
        title: 'Registration successful',
      });
      // navigate('/payments');
    } catch (error) {
      console.error('Error submitting form:', error);
      Swal.fire({
        icon: 'error',
        title: 'Try Again....',
        text: 'Something went wrong',
      });
    }
  };

  return (
    <>
      <form className='row' onSubmit={handleSubmit} encType='multipart/form-data'>
        <div className="form-group col-lg-4">
          <label className="rgform">Name</label>
          <input
            type="text"
            className="form-control"
            value={internData.name}
            placeholder="Enter Your Name"
            readOnly
            name="name"
          />
        </div>
        <div className="form-group col-lg-4">
          <label className="rgform">Email</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter Email id"
            value={internData.email}
            name="email"
            readOnly
            required
          />
        </div>
        <div className="form-group col-lg-4">
          <label className="rgform">Mobile Number</label>
          <input
            type="number"
            className="form-control"
            placeholder="Enter mobile no."
            value={internData.number || internData.mobile}
            name="number"
            maxLength={10}
            readOnly
            required
          />
        </div>
        <div className="form-group col-lg-4">
          <label className="rgform">Interested Course Category</label>
          <input className='form-control' name="intrestedcourse" type='text' value={internData.intrestedcourse ||'--------'} readOnly />
        </div>
        <div className='form-group col-lg-4'>
          <label className="rgform">Selected Course</label>
          <input type='text' className='form-control' name="selected_course" value={internData.selected_course || subscription.title} readOnly />
        </div>
        <div className="form-group col-lg-4">
          <label className="rgform">Mode</label>
          <input type='text' className='form-control' name='mode' value={internData.mode ||'--------'} readOnly />
        </div>
        <div className='form-group  col-lg-4'>
          <label className="rgform">Duration</label>
          <input type='text' className='form-control' name='duration' value={internData.duration || subscription.duration} />
        </div>
        <div className='form-group  col-lg-4'>
          <label className="rgform">Price</label>
          <input type='text' className='form-control' name='price' value={internData.price || subscription.price } />
          
        </div>
        <div className="form-group col-lg-4 d-flex justify-content-end">
          <button type="submit" onClick={handelpayment} className="registerbtn col-lg-8 justify-content-center mt-3 ">
            Submit
          </button>
        </div>
      </form>
    </>
  )
}

export default InternRegistration;
