import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

function Sliderslide() {
    const [coursedata, setCourseData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://sdmipl.com/portal/api/internship/coursepost-view");
                setCourseData(response.data.records);
            } catch (error) {
                console.error("Error fetching data:", error);

            }
        };
        fetchData();
    }, [])
    const handleCourseClick = (courseId, category) => {
        switch (category) {
            case "Frontend Course":
            case "Frontend":
            case "1":
                navigate(`/frontendCourse/${courseId}`);
                break;
            case "Backend Course":
            case "Backend":
            case "2":
                navigate(`/backendcourse/${courseId}`);
                break;
            case "UI/UX Course":
            case "3":
            case "design":
                navigate(`/uiuxCourse/${courseId}`);
                break;
            default:
                break;
        }
    };
    // const setting = {
    //     dots: true,
    //     infinite: true,
    //     speed: 300,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     // autoplay: true,
    //     pauseOnHover: true,
    //     dots: false,
    //     // adaptiveHeight: true, 
    //     }

    var setting = {
        // dots: true,
        infinite: true,
        autoplay:true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
        <>
            <div className="container-fluid mt-5 slidermainslide" >
                <Slider {...setting} >
                    {coursedata && coursedata.map((course, index) => (
                            <div className="card slidermain py-2" key={index} onClick={() => handleCourseClick(course.slug, course.category)}>
                                <div className='d-flex justify-content-center'>
                                    <img className="img-fluid sliderimges" src={course.image} alt={course.title} />
                                </div>
                                <div className="d-flex justify-content-center"><p className="slidertext">{course.title}</p></div>
                            </div>
                    ))}
                </Slider>
            </div>
        </>
    )
}

export default Sliderslide;
