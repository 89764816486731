import React from 'react'
import { Link } from 'react-router-dom';
import not from '../../src/images/Monster 404 Error-amico.png'
function Nopage() {
    return (
        <div className='error-page'>
            <div className="main-wrapper">
                <div className="container error-box">
                   <img className='errimg'  src={not} alt='404' />
                    <h1 className='errhead'>404</h1>
                    <h3 className="h2">
                        <i className="fas fa-exclamation-triangle" /> Oops! Page not found!
                    </h3>
                    <p className="h3 font-weight-normal d-flex justify-content-center">
                        The page you requested was not found.
                    </p>
                    <div className='d-flex justify-content-center'>
                    <Link to="/" className="btn btn-primary d-flex justify-content-center w-25"  >
                        Back to Home
                    </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Nopage;